import React, { useState, useEffect } from "react";
import "./TableOfContents.css";

const TableOfContents = ({ platform }) => {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    const headings = document.querySelectorAll("h1, h2, h3, h4, h5, h6");
    const newSections = [];

    headings.forEach(heading => {
      const id =
        heading.id || heading.textContent.toLowerCase().replace(/\s+/g, "-");
      if (!heading.getAttribute("ignore-toc")) {
        newSections.push({
          id,
          title: heading.textContent,
          level: parseInt(heading.tagName.charAt(1)),
        });
      }
    });

    setSections(newSections);
  }, []);

  return (
    <div className={`table-of-contents ${platform}`}>
      <h2 ignore-toc="true">Table of Contents</h2>
      <ol>
        {sections.map(section => (
          <li key={section.id} className={`toc-level-${section.level}`}>
            <a href={`#${section.id}`} style={{ fontSize: "18px" }}>
              {section.title}
            </a>
          </li>
        ))}
      </ol>
    </div>
  );
};

export default TableOfContents;
