import JoshImage from "../images/team/joshua.png";
import WilliamImage from "../images/team/william.jpeg";
import RobbieImage from "../images/team/robbie.jpg";
import MattImage from "../images/team/matt.png";
import CarsonImage from "../images/team/carson.png";
import AdibImage from "../images/team/adib.png";
import DomImage from "../images/team/dom.png";
import EwenImage from "../images/team/ewen.png";

const events = [
  {
    date: "April 2020",
    description: "BlueFoxHost was founded on the 27th of April, 2020.",
  },
  {
    date: "September 2023",
    description: "BlueFoxHost released its Finland Hosting location.",
  },
];

const teamMembers = [
  {
    name: "Joshua Eubanks",
    title: "Director",
    email: "joshe@bluefoxhost.com",
    twitterLink: "https://twitter.com/josheubanks0",
    instagramLink: "https://instagram.com/josheubanks0",
    imageSrc: JoshImage,
  },
  {
    name: "William",
    title: "Executive",
    email: "williamb@bluefoxhost.com",
    twitterLink: "https://twitter.com/Orashgle",
    imageSrc: WilliamImage,
  },
  {
    name: "Robbie",
    title: "Executive",
    email: "robbiec@bluefoxhost.com",
    twitterLink: "https://twitter.com/_robbiecutler",
    imageSrc: RobbieImage,
  },
  {
    name: "Matt",
    title: "Support Supervisor",
    email: "mattt@bluefoxhost.com",
    twitterLink: "https://twitter.com/tweak4141",
    githubLink: "https://github.com/Tweak4141",
    imageSrc: MattImage,
  },
  {
    name: "Adib",
    title: "Developer",
    email: "adibs@bluefoxhost.com",
    twitterLink: "https://twitter.com/DEV_DIBSTER",
    githubLink: "https://github.com/DEV-DIBSTER",
    imageSrc: AdibImage,
  },
  {
    name: "Carson",
    title: "Moderator",
    twitterLink: "https://twitter.com/CXgraphics1",
    githubLink: "https://github.com/CXgraphics1",
    imageSrc: CarsonImage,
  },
  {
    name: "Dom",
    title: "Moderator",
    instagramLink: "https://instagram.com/domrigoll",
    githubLink: "https://github.com/SignatureDom",
    imageSrc: DomImage,
  },
  {
    name: "Ewen",
    title: "Moderator",
    githubLink: "https://github.com/Frost-Lord",
    imageSrc: EwenImage,
  },
];

export { events, teamMembers };
