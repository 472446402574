import React, { useState, useEffect, useRef } from "react";
import "./OrderModal.css";
import Modal from "./BaseModal";
import Dropdown from "./Dropdown";
//import CAImage from '../images/ca.png';
import USAImage from "../images/us.png";
import DEImage from "../images/de.png";
import FinlandImage from "../images/finland.png";
import GolangImage from "../images/programming/go.svg";
import JavaImage from "../images/programming/java.svg";
import JSImage from "../images/programming/javascript.svg";
import LuaImage from "../images/programming/lua.svg";
import RubyImage from "../images/programming/ruby.svg";
import PythonImage from "../images/programming/python.svg";
import InfoBox from "./InfoBox";

const gameServerLocations = [
  { name: "Finland", image: FinlandImage, disabled: true },
  { name: "Germany", image: DEImage, disabled: true },
];
const allLocations = [
  { name: "Germany", image: DEImage },
  { name: "Finland", image: FinlandImage },
  { name: "Virginia", image: USAImage, disabled: true },
];
const MinecraftTypes = [
  { name: "Paper" },
  { name: "Vanilla" },
  { name: "Forge" },
  { name: "Bungeecord" },
  { name: "Sponge (Vanilla)" },
  { name: "Sponge (Forge)" },
  { name: "Spigot" },
  { name: "Waterfall" },
  { name: "VIAaaS" },
  { name: "Travertine" },
  { name: "Vanilla Bedrock" },
];

const TerrariaTypes = [
  { name: "Vanilla" },
  { name: "TShock" },
  { name: "tModLoader" },
];

const ProgrammingLanguages = [
  { name: "JavaScript", image: JSImage },
  { name: "Java", image: JavaImage },
  { name: "Python", image: PythonImage },
  { name: "Ruby", image: RubyImage },
  { name: "Lua", image: LuaImage },
  { name: "Golang", image: GolangImage },
];
const hostingConfig = {
  minecraft: {
    locations: gameServerLocations,
    serviceTypes: MinecraftTypes,
  },
  bot: {
    locations: allLocations,
    serviceTypes: ProgrammingLanguages,
  },
  terraria: {
    locations: gameServerLocations,
    serviceTypes: TerrariaTypes,
  },
};

const GameHosting = ["minecraft", "terraria"];

const OrderModal = ({ onSubmit, isOpen, onClose, hostingType, planName }) => {
  const focusInputRef = useRef(null);
  var initialOrderModalData = {};
  if (GameHosting.includes(hostingType)) {
    initialOrderModalData = {
      location: gameServerLocations[0].disabled
        ? { name: "" }
        : gameServerLocations[0],
      serverName: "",
      serverType: hostingConfig[hostingType].serviceTypes[0],
    };
  } else {
    initialOrderModalData = {
      location: allLocations[0],
      serverName: "",
      serverType: hostingConfig[hostingType].serviceTypes[0],
    };
  }
  const [formState, setFormState] = useState(initialOrderModalData);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (isOpen && focusInputRef.current) {
      setTimeout(() => {
        focusInputRef.current.focus();
      }, 0);
    }
  }, [isOpen]);

  const handleInputChange = event => {
    const { name, value } = event.target;
    console.log(name, value);
    setFormState(prevFormData => ({
      ...prevFormData,
      [name]: value,
    }));
    console.log(formState);
  };

  const handleLocationChange = location => {
    setFormState(prevFormData => ({
      ...prevFormData,
      location: location,
    }));
  };

  const handleSubmit = event => {
    event.preventDefault();
    if (!formState.location.name) {
      setError({ message: "Location is required." });
      return;
    }
    if (error) {
      setError(null);
    }
    onSubmit(formState);
    setFormState(initialOrderModalData);
  };

  const allowedLocations = hostingConfig[hostingType]?.locations || [];
  const allowedServiceTypes = hostingConfig[hostingType]?.serviceTypes || [];

  return (
    <Modal
      hasCloseBtn={true}
      isOpen={isOpen}
      onClose={onClose}
      title={`Server Configuration: ${planName}`}
    >
      <form onSubmit={handleSubmit}>
        {error && (
          <div className="form-row">
            <InfoBox
              message={[error.message]}
              color="error"
              dismissible={true}
              icon="fa-triangle-exclamation"
              inlinecss="ordermodal-warning-spacing"
            />
          </div>
        )}
        <div className="form-row">
          <label className="input-label" htmlFor="serverName">
            Server Name
          </label>
          <input
            className="input-contact"
            ref={focusInputRef}
            type="text"
            id="serverName"
            name="serverName"
            value={formState.serverName}
            onChange={handleInputChange}
            required
          />
        </div>
        <div className="form-row">
          <InfoBox
            message={[
              "Please do not request alternate locations.",
              <br />,
              "We only offer the locations listed below, no exceptions will be made.",
            ]}
            color="warning"
            dismissible={true}
            icon="fa-triangle-exclamation"
          />
          <label className="input-label" htmlFor="location">
            Location
          </label>
          <Dropdown
            options={allowedLocations}
            selectedOption={formState.location}
            onOptionSelect={handleLocationChange}
          />
        </div>
        {allowedServiceTypes.length > 0 && (
          <div className="form-row">
            <label className="input-label" htmlFor="serverType">
              Server Type
            </label>
            <Dropdown
              options={allowedServiceTypes}
              selectedOption={formState.serverType}
              onOptionSelect={selected =>
                handleInputChange({
                  target: { name: "serverType", value: selected },
                })
              }
            />
          </div>
        )}
        <div className="form-row">
          <button className="btn-send" type="submit">
            Submit
          </button>
          <button className="btn-cancel hidden-large" onClick={onClose}>
            Cancel
          </button>
        </div>
      </form>
    </Modal>
  );
};

export default OrderModal;
