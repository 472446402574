import React from "react";
import "../css/company.css";
import Header from "../components/header";
import Footer from "../components/footer";
import BluefoxLogoFull from "../images/logo-full.png";
import FAQAccordion from "../components/FAQAccordion";
import "../css/banned_countries.css";

const faqItems = [
  {
    question: "Why is my country blocked?",
    answer:
      "At BlueFoxHost, we take abuse with utmost seriousness.  Regrettably, access to our services from your country has been restricted due to persistent ongoing abuse concerns.",
  },
  {
    question: "Will my country be unblocked?",
    answer:
      "Our team takes the matter of abuse seriously and continuously evaluates the situation. We cannot provide a specific timeframe for any potential changes.",
  },
];

function CompanyPage() {
  return (
    <div>
      <Header />

      <section className="company">
        <div className="flex-about">
          <div className="side-about">
            <p className="title">Blocked Countries</p>
            <p className="text-about">
              Registrations from the following countries are currently
              restricted.
            </p>
            <div style={{ marginTop: "25px" }}>
              <ul style={{ marginLeft: "25px" }}>
                <li>Indonesia</li>
              </ul>
              <FAQAccordion faqItems={faqItems} css="banned-faq" />
            </div>
          </div>
          <div className="side-about center-about">
            <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default CompanyPage;
