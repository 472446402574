import React, { useState } from "react";
import OrderModal from "./OrderModal";

const ProductBox = ({
  planName,
  gb,
  features,
  price,
  type,
  icon,
  stock = true,
}) => {
  let color = "";
  if (type !== "bot") color = type + "-text";
  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };
  return (
    <div className="product-game-box">
      <i className={"plan-svg fa-solid fa-" + icon}></i>
      <span className="product-plan-name">{planName}</span>
      <p className="plan-gb">{gb}</p>
      {features.map((feature, index) => (
        <p className="plan-feature" key={index}>
          {feature}
        </p>
      ))}
      <p className={"plan-price " + color}>{price}</p>
      {stock ? (
        <button onClick={openModal} className={"btn-order " + type}>
          Order Now
        </button>
      ) : (
        <button disabled className="btn-order warning">
          Out Of Stock
        </button>
      )}
      {isModalOpen && (
        <OrderModal
          isOpen={isModalOpen}
          onClose={closeModal}
          hostingType={type}
          planName={planName}
          onSubmit={formData => {
            if (!formData.location) return false;
            closeModal();
            const message = `Dear Support,

I am interested in one of BlueFoxHost's plans.
I would like my server name to be **${formData.serverName}**.
Additional Comments:

Server Name: **${formData.serverName}**

**---- DO NOT CHANGE ANY PLAN INFO BELOW THIS LINE. CHANGING INFO WILL INVALIDATE YOUR REQUEST ----**
Plan Selected: **${planName}** plan.
Location: **${formData.location.name}**.
Server Type: ${
              formData.serverType?.name
                ? `**${formData.serverType.name}**.`
                : `No special type selected.`
            }
            `;
            const url = `https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=4&subject=${planName}&message=${message}`;
            window.open(encodeURI(url), "_blank");
          }}
        />
      )}
    </div>
  );
};

export default ProductBox;
