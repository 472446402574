import React from "react";
import "../css/products.css";
import Header from "../components/header";
import Footer from "../components/footer";
import MinecraftImage from "../images/products/minecraft.webp";
import TerrariaImage from "../images/products/terraria.webp";
import BotImage from "../images/products/discord.webp";
const Products = () => {
  return (
    <div>
      <Header />
      <section className="products">
        <p className="title">Our Products</p>
        <p className="subtitle">
          Here's the list of all our products, welcome to a world of choices.
        </p>

        <div className="products-boxes">
          <div
            className="product"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${MinecraftImage})`,
            }}
          >
            <p className="product-name">Minecraft Hosting</p>
            <p className="product-price">
              $0.00<span className="forever"></span>
            </p>
            <a href="/products/minecraft">
              <button className="btn-order">Order Now</button>
            </a>
          </div>
          <div
            className="product"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${BotImage})`,
            }}
          >
            <p className="product-name">Discord Bot Hosting</p>
            <p className="product-price">
              $0.00<span className="forever"></span>
            </p>
            <a href="/products/bots">
              <button className="btn-order">Order Now</button>
            </a>
          </div>
          <div
            className="product"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${TerrariaImage})`,
            }}
          >
            <p className="product-name">Terraria Hosting</p>
            <p className="product-price">
              $0.00<span className="forever"></span>
            </p>
            <a href="/products/terraria">
              <button className="btn-order">Order Now</button>
            </a>
          </div>
        </div>
        <div className="products-boxes">
          <div
            className="product"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url()`,
            }}
          >
            <p className="product-name">Coming Soon</p>
            <p className="product-price">
              $0.00<span className="forever"></span>
            </p>
            <a href="">
              <button className="btn-order">Coming Soon</button>
            </a>
          </div>
          <div
            className="product"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url()`,
            }}
          >
            <p className="product-name">Coming Soon</p>
            <p className="product-price">
              $0.00<span className="forever"></span>
            </p>
            <a href="">
              <button className="btn-order">Coming Soon</button>
            </a>
          </div>
          <div
            className="product"
            style={{
              background: `linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url()`,
            }}
          >
            <p className="product-name">Coming Soon</p>
            <p className="product-price">
              $0.00<span className="forever"></span>
            </p>
            <a href="">
              <button className="btn-order">Coming Soon</button>
            </a>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Products;
