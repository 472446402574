import React, { useState, useEffect, useRef } from "react";
import "./Dropdown.css";
const Dropdown = ({ options, selectedOption, onOptionSelect }) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleBodyClick = e => {
      if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
        setIsDropdownOpen(false);
      }
    };

    document.body.addEventListener("click", handleBodyClick);

    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);

  const handleOptionSelect = option => {
    if (option.disabled) {
      return;
    }

    onOptionSelect(option);
    setIsDropdownOpen(false);
  };

  return (
    <div className="custom-dropdown" ref={dropdownRef}>
      <div
        className="selected-option"
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {selectedOption?.image && (
          <img
            src={selectedOption?.image}
            alt={selectedOption?.name}
            className="flag-icon"
          />
        )}
        {selectedOption?.name}
        <i
          className={`fa-solid fa-chevron-${isDropdownOpen ? "up" : "down"}`}
        ></i>
      </div>
      {isDropdownOpen && (
        <ul className="dropdown-options">
          {options.map(option => (
            <li
              key={option.name}
              onClick={() => handleOptionSelect(option)}
              className={`dropdown-option ${option.disabled ? "disabled" : ""}`}
            >
              {option.image && (
                <img
                  src={option.image}
                  alt={option.name}
                  className="flag-icon"
                />
              )}
              {option.name}
              {option.disabled && (
                <span className="out-of-stock">(Out of Stock)</span>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;
