import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../css/index.css";

const GetStarted = () => {
  const services = ["Minecraft Server", "Terraria Hosting", "Discord Bot"];
  const [dropdownActive, setDropdownActive] = useState(false);
  const navigate = useNavigate();

  const serviceUrls = {
    "Choose a service that meets your needs": "/products",
    "Minecraft Server": "/products/minecraft",
    "Terraria Hosting": "/products/terraria",
    "Discord Bot": "/products/bots",
  };

  const handleServiceClick = service => {
    const url = serviceUrls[service];
    if (url) {
      navigate(url);
    }
  };

  const handleToggleDropdown = () => {
    setDropdownActive(!dropdownActive);
  };

  const handleStartClick = () => {
    navigate("/products");
  };

  return (
    <div className={`wrapper ${dropdownActive ? "active" : ""}`}>
      <div className="select-btn" onClick={handleToggleDropdown}>
        <span>Choose a service that meets your needs</span>
        <i className="fa-solid fa-angle-down"></i>
      </div>

      <div className={`content-box ${dropdownActive ? "active" : ""}`}>
        <ul className="options">
          {services.map((service, index) => (
            <li key={index} onClick={() => handleServiceClick(service)}>
              {service}
            </li>
          ))}
        </ul>

        <button
          id="getstarted"
          className="get-started"
          onClick={handleStartClick}
        >
          View All Services
        </button>
      </div>
    </div>
  );
};

export default GetStarted;
