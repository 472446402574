import React, { useState } from "react";
import "../css/company.css";
import "../css/hardware.css";
import "../css/locations.css";

import Header from "../components/header";
import Footer from "../components/footer";
import BluefoxLogoFull from "../images/logo-full.png";
import servers from "../strings/servers";

function LocationList({ locations }) {
  const [activeLocations, setActiveLocations] = useState(
    new Array(locations.length).fill(false)
  );

  const toggleLocation = index => {
    const updatedActiveLocations = [...activeLocations];
    updatedActiveLocations[index] = !updatedActiveLocations[index];
    setActiveLocations(updatedActiveLocations);
  };

  return (
    <div>
      {locations.map((result, index) => (
        <div
          className={`location-container ${
            activeLocations[index] ? "active" : ""
          }`}
          key={index}
          onClick={() => toggleLocation(index)}
        >
          <div className="location-details">
            <div className="flag-container">
              <img className="flag-image" alt="Flag" src={result.image} />
            </div>
            <div className="location-info">
              <div>
                <p className="location-title">
                  {result.location}{" "}
                  {!result.released && (
                    <div className="pillbox p-warning title-tag hidden-mobile mid-padding">
                      Not Released
                    </div>
                  )}
                </p>
                <p className="country-name">{result.countryName}</p>
              </div>
            </div>
            <i
              className={`fas fa-angle-down caret-icon ${
                activeLocations[index] ? "rotate" : ""
              }`}
            ></i>
          </div>
          <div
            className={`hardware-container ${
              activeLocations[index] ? "active" : ""
            }`}
          >
            <div className="hardware-card-container">
              {result.specs.map((spec, specIndex) => (
                <div className="hardware-card" key={specIndex}>
                  <p className="section-title">
                    {spec.services}
                    {result.owned && (
                      <div className="pillbox p-info title-tag">
                        Owned Hardware
                      </div>
                    )}
                  </p>
                  <div className="tags">
                    {!spec.released && (
                      <div className="pillbox p-warning">Not Released</div>
                    )}
                  </div>
                  <div className="hardware-info">
                    <i className="t-info fa-sharp fa-solid fa-microchip"></i>
                    <span className="section-feature">{spec.cpu}</span>
                    <br />
                    <i className="t-info fa-sharp fa-solid fa-memory"></i>
                    <span className="section-feature">{spec.ram}</span>
                    <br />
                    <i className="t-info fa-sharp fa-solid fa-hard-drive"></i>
                    <span className="section-feature">{spec.disk}</span>
                    <br />
                    <i className="t-info fa-sharp fa-solid fa-network-wired"></i>
                    <span className="section-feature">
                      {spec.uplink} Uplink
                    </span>
                    <br />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

function HardwarePage() {
  return (
    <div>
      <Header />

      <section className="company">
        <div className="flex-about">
          <div className="side-about">
            <p className="title">Our Hardware</p>
            <p className="subtitle">
              Discover our powerful enterprise-grade servers
            </p>

            <p className="text-about">
              You can find out about the various hardware used by BlueFoxHost
              that ensures you get a great experience with hosting your servers.
            </p>
          </div>
          <div className="side-about center-about">
            <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
          </div>
        </div>
        <LocationList locations={servers} />
      </section>

      <Footer />
    </div>
  );
}

export default HardwarePage;
