import React, { useState } from "react";
import "./InfoBox.css";
function InfoBox({ message, color, dismissible, icon, inlinecss }) {
  const [showInfoBox, setShowInfoBox] = useState(true);

  const handleClose = () => {
    setShowInfoBox(false);
  };
  if (!inlinecss) inlinecss = "";
  return (
    showInfoBox && (
      <div className={`info-box ${color} ${inlinecss}`}>
        {icon && <i class={`fa-solid ${icon} fa-2x info-box-icon`}></i>}
        {message}
        {dismissible && (
          <span className="fa-stack fa-lg info-close-btn">
            <button className="info-close-btn" onClick={handleClose}>
              <i className="fas fa-times fa-stack-1x fa-inverse"></i>
            </button>
          </span>
        )}
      </div>
    )
  );
}

export default InfoBox;
