import React, { useState } from "react";
import "../css/company.css";
import Header from "../components/header";
import Footer from "../components/footer";
import BluefoxLogoFull from "../images/logo-full.png";
import TeamMember from "../components/TeamMember";
import s from "../css/company.css";
import Timeline from "../components/Timeline";
import { Waypoint } from "react-waypoint";
import { events, teamMembers } from "../strings/bluefoxhost";
import Number from "../components/Number";
function CompanyPage() {
  const [appear, setAppear] = useState(false);

  return (
    <div>
      <Header />

      <section className="company">
        <div className="flex-about">
          <div className="side-about">
            <p className="title">About BlueFox Host</p>
            <p className="subtitle">
              Here, we invite you to delve into the heart of our story, values,
              and mission.
            </p>

            <p className="text-about">
              BlueFoxHost was created on{" "}
              <span className="bold">04/27/2020</span> with the goal of
              providing a secure and stable platform for their own use. Our team
              is a group of dedicated individuals who are dedicated to providing
              the best experience for our clients.
            </p>
            <p className="text-about">
              We put customer satisfaction above all else. We are dedicated to
              providing the smoothest and most efficient experience for our
              users. Most of BlueFoxHost's services can be deployed within a few
              seconds.
            </p>
            <p className="text-about">
              Shoutout to our{" "}
              <a className="link-about" href="#team">
                team
              </a>{" "}
              for their dedication and hard work.
            </p>
          </div>
          <div className="side-about center-about">
            <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
          </div>
        </div>
      </section>
      <section className="company" id="team">
        <p className="title">Team</p>
        <p className="subtitle">
          Discover the creative geniuses shaping BlueFoxHost's enchanting
          experiences – say hello to our remarkable team!
        </p>

        <div className="grid-team">
          {teamMembers.map((member, index) => (
            <TeamMember
              key={index}
              name={member.name}
              title={member.title}
              email={member?.email}
              twitterLink={member?.twitterLink}
              instagramLink={member?.instagramLink}
              githubLink={member?.githubLink}
              gitLink={member?.gitLink}
              mastodonLink={member?.mastodonLink}
              imageSrc={member.imageSrc}
            />
          ))}
        </div>

        <div className="join-discord">
          <p className={s.small_title}>Want to say hi to our team?</p>
          <p className="subtitle">
            Are you excited to get to know the faces behind the scenes? Do you
            have a friendly message you've been eager to share?
          </p>
          <a href="https://discord.gg/jzCDhUU7uH">
            <button className="btn-discord">Join Discord</button>
          </a>
        </div>
      </section>
      <section className="company">
        <p className="title" style={{ textAlign: "center" }}>
          Timeline
        </p>
        <p className="subtitle" style={{ textAlign: "center" }}>
          The timeline of events for BlueFoxHost.
        </p>
        <Timeline events={events} />
      </section>
      <section class="company">
        <div className="about-info-container ">
          <div className="about-page-row">
            <div className="about-info-box">
              <Waypoint onEnter={() => setAppear(true)} />
              <div className="about-info-number">
                {appear && (
                  <div>
                    <Number num={1000} />
                  </div>
                )}
              </div>
              <div className="about-info-label">Clients Served</div>
            </div>
            <div className="about-info-box">
              <div className="about-info-number">
                {appear && (
                  <div>
                    <Number num={2000} />
                  </div>
                )}
              </div>
              <div className="about-info-label">Servers Hosted</div>
            </div>
            <div className="about-info-box">
              <div className="about-info-number">
                {appear && (
                  <div>
                    <Number num={1000} />
                  </div>
                )}
              </div>
              <div className="about-info-label">Tickets Answered</div>
            </div>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default CompanyPage;
