import React from "react";
import "../css/company.css";
import Header from "../components/header";
import Footer from "../components/footer";
import BluefoxLogoFull from "../images/logo-full.png";
import Banner1 from "../images/branding/BlueFox_banner_1.jpg";
import Banner2 from "../images/branding/BlueFox_banner.jpg";
import SquareLogo1 from "../images/branding/BlueFox_Discord_Icon.jpg";
import SquareLogo2 from "../images/branding/BlueFox_Logo.png";
import SquareLogo3 from "../images/branding/BlueFox_Logo1.png";
import SquareLogo4 from "../images/branding/BlueFox_Logo_Presentation.png";
import SquareLogo5 from "../images/branding/BlueFox_Mascot.png";
import HalloweenSquareLogo from "../images/branding/BlueFox.png";
import HalloweenSquareLogo1 from "../images/branding/BlueFoxTP.png";
import WordmarkBanner from "../images/branding/BlueFox_Wordmark.png";
import WordmarkBanner2 from "../images/branding/bluefox_wordmark_1.png";
import "../css/carousel.scss";
import "../css/branding.css";

const ColorsSection = () => {
  const brandColors = [
    { name: "BlueFox Blue (Primary Color)", hex: "#2db0fe" },
    { name: "Secondary Color", hex: "#e2ebf4" },
    { name: "Accent Color", hex: "#1a1c1e" },
    { name: "Background Color", hex: "#101213" },
  ];

  return (
    <section className="branding-section">
      <h2>Brand Colors</h2>
      <div className="color-list">
        {brandColors.map((color, index) => (
          <div key={index} className="color-item">
            <div
              className="color-box"
              style={{ backgroundColor: color.hex }}
            ></div>
            <div className="color-info">
              <span className="color-name">{color.name}</span>
              <br />
              <span className="color-hex">{color.hex}</span>
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

const SquareLogoSection = () => {
  const logos = [
    SquareLogo1,
    SquareLogo2,
    SquareLogo3,
    SquareLogo4,
    SquareLogo5,
  ];
  const festivelogos = [HalloweenSquareLogo, HalloweenSquareLogo1];
  return (
    <section className="branding-section">
      <h2>Square Logos</h2>
      <div className="logo-container">
        {logos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>
      <p>
        The square logo is used when using the banner is not feasible, or there
        is limited space on a layout. The square logo can also be used when
        attribution to BlueFoxHost has been made clear. The logo must not be
        altered, stretched, or otherwise modified without the explicit consent
        of BlueFoxHost.
      </p>
      <h2>Festive Logos & Animated Logos</h2>
      <div className="logo-container">
        {festivelogos.map((logo, index) => (
          <div key={index} className="logo-item">
            <img src={logo} alt={`Logo ${index + 1}`} />
          </div>
        ))}
      </div>
      <p>
        The festive square logo is used ONLY for themes where the BlueFoxHost
        Blue impairs the function and theme of a UI. The above conditions
        regarding square logos still apply. The logo must not be altered,
        stretched, or otherwise modified without the explicit consent of
        BlueFoxHost.
      </p>
    </section>
  );
};

const DownloadSection = () => {
  return (
    <section className="branding-section">
      <h2>Download Assets</h2>
      <p>
        Download the full branding kit.
        <br />
        <a href="/downloads/branding.zip">
          <button className="get-started" style={{ width: "100px" }}>
            Download
          </button>
        </a>
      </p>
    </section>
  );
};

const BannerSection = () => {
  return (
    <section className="branding-section">
      <h2>Banners</h2>
      <p>
        In this section, you'll find guidelines for using banners in our brand.
        Banners are important for conveying our brand on websites.
      </p>
      <div className="banner-example">
        <h3>Banner 1</h3>
        <img src={Banner1} alt="Example Banner 1" />
      </div>
      <div className="banner-example">
        <h3>Banner 2</h3>
        <img src={Banner2} alt="Example Banner 2" />
      </div>
      <p>
        The banner is preferred when displaying content not on BlueFoxHost's
        official website. The banner must not be altered, stretched, or
        otherwise modified without the explicit consent of BlueFoxHost.
      </p>
      <h2>Festive Banners</h2>
      <div className="banner-example" style={{ alignContent: "center" }}>
        <h3>Wordmark Banner</h3>
        <img src={WordmarkBanner} alt="Example Banner" />
        <img
          src={WordmarkBanner2}
          alt="Example Banner"
          style={{ marginBottom: "15px" }}
        />
      </div>
      <p>
        The Wordmark banner is preferred when displaying content not on
        BlueFoxHost's official website, and the standard BlueFoxHost banner is
        not suitable for UI purposes. The banner must not be altered, stretched,
        or otherwise modified without the explicit consent of BlueFoxHost.
      </p>
    </section>
  );
};

function BrandingPage() {
  return (
    <div>
      <Header />

      <section className="company">
        <div className="flex-about align-center">
          <div className="side-about">
            <p className="title">Branding Guidelines</p>
            <p className="text-about">
              When displaying the company logo, please follow these guidelines.
            </p>
          </div>
          <div className="side-about center-about">
            <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
          </div>
          <SquareLogoSection />
          <ColorsSection />
          <DownloadSection />
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default BrandingPage;
