import React, { useEffect, useRef } from "react";
import "./timeline.css";

const AnimatedTimeline = ({ events }) => {
  const itemsRef = useRef([]);

  const isItemInView = item => {
    const rect = item.getBoundingClientRect();
    return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <=
        (window.innerHeight || document.documentElement.clientHeight) &&
      rect.right <= (window.innerWidth || document.documentElement.clientWidth)
    );
  };

  const show = () => {
    for (let i = 0; i < itemsRef.current.length; i++) {
      const item = itemsRef.current[i];
      if (isItemInView(item)) {
        item.classList.add("show");
      }
    }
  };

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, events.length);
    window.addEventListener("load", show);
    window.addEventListener("resize", show);
    window.addEventListener("scroll", show);

    return () => {
      window.removeEventListener("load", show);
      window.removeEventListener("resize", show);
      window.removeEventListener("scroll", show);
    };
  }, [events]);

  return (
    <div className="timeline">
      <ul>
        {events.map((event, index) => (
          <li
            key={index}
            ref={el => (itemsRef.current[index] = el)}
            className={index % 2 === 0 ? "even" : "odd"}
          >
            <div className="timeline-content">
              <time>{event.date}</time>
              <p>{event.description}</p>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default AnimatedTimeline;
