import React from "react";

const TeamMember = ({
  imageSrc,
  name,
  title,
  email,
  twitterLink,
  instagramLink,
  githubLink,
  mastodonLink,
  gitLink,
}) => {
  return (
    <div className="team-user">
      <div className="imageDiv" style={{ "--background": `url(${imageSrc})` }}>
        <img className="userImage" src={imageSrc} alt="" />
      </div>

      <div className="user-section">
        <p className="user-name">{name}</p>
        <p className="user-title">{title}</p>

        {email && (
          <a href={`mailto:${email}`}>
            <i className="user-promote fa-regular fa-envelope"></i>
          </a>
        )}
        {twitterLink && (
          <a target="_blank" rel="noreferrer" href={twitterLink}>
            <i className="user-promote fa-brands fa-x-twitter"></i>
          </a>
        )}
        {instagramLink && (
          <a target="_blank" rel="noreferrer" href={instagramLink}>
            <i className="user-promote fa-brands fa-instagram"></i>
          </a>
        )}
        {githubLink && (
          <a target="_blank" rel="noreferrer" href={githubLink}>
            <i className="user-promote fa-brands fa-github"></i>
          </a>
        )}
        {mastodonLink && (
          <a target="_blank" rel="noreferrer" href={mastodonLink}>
            <i className="user-promote fa-brands fa-mastodon"></i>
          </a>
        )}
        {gitLink && (
          <a target="_blank" rel="noreferrer" href={gitLink}>
            <i className="user-promote fa-brands fa-git-alt"></i>
          </a>
        )}
      </div>
    </div>
  );
};

export default TeamMember;
