import React from "react";
import s from "../css/partners.css";
import Header from "../components/header";
import Footer from "../components/footer";
import DiscordSVG from "../images/discord.svg";
const PartnersPage = () => {
  return (
    <div>
      <Header />
      <section className="partners">
        <p className="title">Partners</p>
        <p className="subtitle">
          Experience the power of collaborative partnerships as we join forces
          with trusted allies in innovation and growth, fostering synergies that
          drive success.
        </p>
        <div className="partners-div">
          <div className="partner">
            <div className="user">
              <img
                src="/images/partners/cesiumlabs.webp"
                alt="User Avatar"
                className="avatar"
              />
              <div className="userbar">
                <p className="name">Cesium Labs</p>
              </div>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              className="discordIcon"
              href="https://discord.gg/mxngNnRP3j"
            >
              <img src={DiscordSVG} alt="Icon" />
            </a>
          </div>
          <div className="partner">
            <div className="user">
              <img
                src="/images/partners/woc.webp"
                alt="User Avatar"
                className="avatar"
              />
              <div className="userbar">
                <p className="name">World of Coding</p>
              </div>
            </div>
            <a
              target="_blank"
              rel="noreferrer"
              className="discordIcon"
              href="https://discord.gg/program"
            >
              <img src={DiscordSVG} alt="Icon" />
            </a>
          </div>
        </div>
        <div className="become-partner">
          <p className={s.small_title}>Want to become a partner?</p>
          <p className="subtitle">
            Join Forces with Us – Partner for Success! Explore collaboration
            opportunities and become a catalyst for innovation
          </p>
          <a href="/contact">
            <button className="btn-partner">Contact Us</button>
          </a>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PartnersPage;
