import React from "react";
import "./Features.css";

const Features = ({ features, itemcss = "", listcss = "" }) => {
  return (
    <div className={`flex-feature-list mt-4 ${listcss}`}>
      {features.map((feature, index) => (
        <div key={index} className={`feature ${itemcss}`}>
          <h3 className="item-font">
            <i className={feature.icon}></i> {feature.feature}
          </h3>
        </div>
      ))}
    </div>
  );
};

export default Features;
