import USAImage from "../images/us.png";
import DEImage from "../images/de.png";
import FiImage from "../images/finland.png";
import NlImage from "../images/nl.png";

const servers = [
  {
    locationId: "ashburn",
    location: "Ashburn, Virginia",
    countryCode: "us",
    countryName: "USA",
    ip: "va.us.ping.bluefoxhost.com:3000/ping",
    image: USAImage,
    owned: false,
    released: true,
    specs: [
      {
        released: true,
        services: "Bot Hosting",
        cpu: "AMD EPYC 7002",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
    ],
  },
  {
    locationId: "helsinki",
    location: "Helsinki, Uusimaa",
    countryCode: "fi",
    countryName: "Finland",
    ip: "hel.fi.ping.bluefoxhost.com:3000/ping",
    image: FiImage,
    owned: false,
    released: true,
    specs: [
      {
        released: true,
        location: "Helsinki, Finland",
        services: "Game Hosting",
        cpu: "AMD Ryzen 9 3900",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
      {
        released: true,
        location: "Helsinki, Finland",
        services: "Bot Hosting",
        cpu: "AMD Ryzen 9 3900",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
    ],
  },
  {
    locationId: "nurnberg",
    location: "Nürnberg, Bavaria",
    countryCode: "de",
    countryName: "Germany",
    ip: "nbg.de.ping.bluefoxhost.com:3000/ping",
    image: DEImage,
    owned: false,
    released: true,
    specs: [
      {
        released: true,
        services: "Game Hosting",
        cpu: "AMD Ryzen 5 3600",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
      {
        released: true,
        services: "Bot Hosting",
        cpu: "AMD Ryzen 5 3600",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
    ],
  },
  {
    locationId: "newyork",
    location: "New York, New York",
    countryCode: "us",
    countryName: "USA",
    ip: "nyc.us.ping.bluefoxhost.com:3000/ping",
    image: USAImage,
    owned: true,
    released: false,
    specs: [
      {
        released: false,
        services: "Not Available",
        cpu: "AMD EPYC 7002",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
    ],
  },
  {
    locationId: "amsterdam",
    location: "Amsterdam, North Holland",
    countryCode: "nl",
    countryName: "Netherlands",
    ip: "ams.nl.ping.bluefoxhost.com:3000/ping",
    image: NlImage,
    owned: true,
    released: false,
    specs: [
      {
        released: false,
        services: "Not Available",
        cpu: "Intel Xeon E52620",
        ram: "DDR4 Memory",
        disk: "NVMe Disk (RAID)",
        uplink: "1GB/s",
      },
    ],
  },
];

export default servers;
