import React, { useState } from "react";
import Tippy from "@tippyjs/react";
import BlueFoxLogo from "../images/logo-text.webp";
import "../css/header.css";

function CompanyDropdown() {
  return (
    <div className="company-dropdown">
      <a href="/company/about">
        <div className="section-dropdown">
          <div className="section-div-dropdown icon-padding center-dropdown fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-book"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">About</p>
            <p className="section-description-dropdown">
              Learn all about BlueFoxHost
            </p>
          </div>
        </div>
      </a>

      <a href="/company/hardware">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-server"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Hardware</p>
            <p className="section-description-dropdown">
              See our powerful hardware
            </p>
          </div>
        </div>
      </a>
      {/*
      <a href="/company/team">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-user-group"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Team</p>
            <p className="section-description-dropdown">
              Meet our dedicated team
            </p>
          </div>
        </div>
      </a>
  */}
      <a href="/company/careers">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-briefcase"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Careers</p>
            <p className="section-description-dropdown">
              Careers at BlueFoxHost
            </p>
          </div>
        </div>
      </a>

      <a href="/company/partners">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-handshake"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Partners</p>
            <p className="section-description-dropdown">
              Meet our supportive partners
            </p>
          </div>
        </div>
      </a>

      <div className="policies">
        <a href="/terms" className="policy-page">
          Terms of Service
        </a>
        <a href="/privacy" className="policy-page">
          Privacy Policy
        </a>
        <a href="/sla" className="policy-page">
          Service Level Agreement
        </a>
        <a href="/company/brand" className="policy-page">
          Brand Guidelines
        </a>
      </div>
    </div>
  );
}

function ProductsDropdown() {
  return (
    <div className="company-dropdown">
      <a href="/products/bots">
        <div className="section-dropdown">
          <div className="section-div-dropdown icon-padding center-dropdown fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-robot"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Discord Bots</p>
            <p className="section-description-dropdown">
              See our bot hosting plans.
            </p>
          </div>
        </div>
      </a>

      <a href="/products/minecraft">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-earth-americas"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Minecraft</p>
            <p className="section-description-dropdown">
              See our Minecraft plans.
            </p>
          </div>
        </div>
      </a>

      <a href="/products/terraria">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-tree"></i>
          </div>
          <div className="section-div-dropdown">
            <p className="section-name-dropdown">Terraria</p>
            <p className="section-description-dropdown">
              See our Terraria plans.
            </p>
          </div>
        </div>
      </a>
      <div className="policies">
        <a href="/products" className="policy-page">
          See all products <span className="fa fa-solid fa-arrow-right"></span>
        </a>
      </div>
    </div>
  );
}

function LoginBox() {
  return (
    <div className="login-box">
      <a href="https://billing.bluefoxhost.com/login">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-arrow-right-to-bracket"></i>
          </div>
          <div className="section-div-dropdown login">
            <p className="section-name-dropdown login">Billing Login</p>
          </div>
        </div>
      </a>

      <a href="https://panel.bluefoxhost.com/auth/login">
        <div className="section-dropdown">
          <div className="section-div-dropdown center-dropdown icon-padding fa-stack fa-2x">
            <i className="icon-dropdown fa fa-circle fa-stack-2x"></i>
            <i className="icon-dropdown fa-stack-1x fa-solid fa-arrow-right-to-bracket"></i>
          </div>
          <div className="section-div-dropdown login">
            <p className="section-name-dropdown login">Panel Login</p>
          </div>
        </div>
      </a>
    </div>
  );
}
function MobileHeader({ showing }) {
  return (
    <div
      id="header-mobile"
      className="header-mobile"
      style={{ display: showing ? "block" : "none" }}
    >
      <div className="logo-box">
        <img className="logo-mobile" src="/images/logo-text.webp" alt="Logo" />
      </div>

      <a className="mobile-rel" href="/">
        Home
      </a>
      <a className="mobile-rel" href="/products">
        Products
      </a>
      <a className="mobile-rel" href="/locations">
        Locations
      </a>
      <a className="mobile-rel" href="/contact">
        Contact
      </a>
      <a className="mobile-rel" href="/company/hardware">
        Hardware
      </a>
      <a className="mobile-rel" href="/company/about">
        About
      </a>
      {/* 
      <a className="mobile-rel" href="/company/team">
        Team
      </a>
      */}
      <a className="mobile-rel" href="/company/partners">
        Partners
      </a>
      <a className="mobile-rel" href="/company/brand">
        Brand
      </a>

      <div className="header-box-bottom">
        <div className="top"></div>
        <a href="https://billing.bluefoxhost.com/login" className="mobile-sec">
          Billing Login
        </a>
        <a
          href="https://panel.bluefoxhost.com/auth/login"
          className="mobile-sec"
        >
          Panel Login
        </a>
        <a
          href="https://billing.bluefoxhost.com/register.php"
          className="mobile-sec"
        >
          Register
        </a>
        {/* You can uncomment the following lines if needed */}
        {/* <div className="line-header"></div> */}
        {/* <p className="company-name-header">© {new Date().getFullYear()} BlueFox Host</p> */}
        <div className="bottom"></div>
      </div>
    </div>
  );
}

function Header() {
  const [showing, setShowing] = useState(false);

  const toggleMobileHeader = () => {
    setShowing(!showing);
  };
  return (
    <div>
      <header className="header">
        <div className="flex">
          <div className="logo-div">
            <a href="/">
              <img
                className="logo"
                src="/images/logo-text.webp"
                alt="Logo BlueFoxHost"
              />
            </a>
          </div>
        </div>
        <div className="flex">
          <div className="links">
            <a href="/" className="section">
              Home
            </a>
            <Tippy
              interactive={true}
              allowHTML={true}
              content={<ProductsDropdown />}
              placement="bottom"
              theme="company"
            >
              <a href="/products" className="section">
                Products
              </a>
            </Tippy>
            <a href="/locations" className="section">
              Locations
            </a>
            <a href="/contact" className="section">
              Contact
            </a>
            <Tippy
              interactive={true}
              allowHTML={true}
              content={<CompanyDropdown />}
              placement="bottom"
              theme="company"
            >
              <a className="section">Company</a>
            </Tippy>
          </div>
        </div>
        <div className="flex">
          <div className="btns">
            <Tippy
              interactive={true}
              allowHTML={true}
              content={<LoginBox />}
              placement="bottom"
              theme="company"
            >
              <a id="login" className="section">
                Login
              </a>
            </Tippy>
            <a href="https://billing.bluefoxhost.com/register.php">
              <button className="btn-header">Sign Up</button>
            </a>
          </div>
          <div className="mobile">
            <i
              id="mobile-bars"
              className="bars fa-solid fa-bars-staggered"
              onClick={toggleMobileHeader}
            ></i>
          </div>
        </div>
      </header>
      <MobileHeader showing={showing} />
    </div>
  );
}

export default Header;
