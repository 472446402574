import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import ProductBox from "../components/ProductBox";
import TerrariaTreeImage from "../images/products/terraria-tree.webp";
/* ------- PANELS ------- */
import Features from "../components/Features";
import FAQAccordion from "../components/FAQAccordion";
import ProductDetail from "../components/ProductDetail";
import InfoBox from "../components/InfoBox";

import { faqItems, productData, featureData } from "../strings/productPanels";
const TerrariaHosting = () => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("https://api.bluefoxhost.com/products/3")
      .then(response => response.json())
      .then(data => {
        setApiData(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error("Error fetching product data: ", error);
      });
  }, []);
  return (
    <div>
      <Header />

      <section className="products">
        <div className="product-box">
          <div className="product-side">
            <i className="controler fa-solid fa-gamepad"></i>
            <span className="product-name-game">Terraria Hosting</span>
            <p className="product-title terraria-text">
              Fast and reliable Terraria hosting.
            </p>
            <p className="product-description">
              Terraria is a 2D sandbox game with gameplay that revolves around
              exploration, building, crafting, combat, survival, and mining,
              playable in both single-player and multiplayer modes.
            </p>
            {/* <a href="https://billing.bluefoxhost.com/index.php?rp=/store/terraria-hosting">
                            <button className="btn-product-box terraria">
                                Order Now
                            </button>
                        </a> */}
          </div>
          <div className="product-side-right">
            <img
              className="product-image"
              src={TerrariaTreeImage}
              alt="Product"
            />
          </div>
        </div>
        <div className="product-box-features">
          <h4
            className="section-heading"
            style={{ marginBottom: "0", textTransform: "uppercase" }}
          >
            Our basic features at no extra cost
          </h4>
          <p style={{ fontSize: "0.9rem" }} className="section-subheading">
            BlueFoxHost provides you with the ability to fully customize your
            hosting experience.
          </p>
          <Features features={featureData} itemcss="te" />
        </div>
        <div className="products-boxes">
          <InfoBox
            message={[
              <p>
                Terraria Hosting signups have been suspended. DO NOT REQUEST
                SERVERS. Learn more by clicking{" "}
                <a
                  style={{ textDecoration: "underline", color: "#2cb1f8" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bluefoxhost.com/company/announcements/1/Suspension-of-New-Signups-for-Free-Tier-for-Game-Hosting"
                >
                  here
                </a>
                .
              </p>,
            ]}
            color="error"
            dismissible={false}
            icon="fa-triangle-exclamation"
            inlinecss="info-box-title"
          />
        </div>
        {!apiData && !loading && (
          <div className="products-boxes">
            <InfoBox
              message={[
                <p>
                  An error occurred while loading products. Contact support{" "}
                  <a
                    style={{ textDecoration: "underline", color: "#2cb1f8" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=1"
                  >
                    here
                  </a>
                  .
                </p>,
              ]}
              color="error"
              dismissible={false}
              icon="fa-triangle-exclamation"
              inlinecss="info-box-title"
            />
          </div>
        )}
        <div className="product-box-second">
          <div className="products-boxes">
            {apiData &&
              apiData.plans.map(plan => (
                <ProductBox
                  key={plan.id}
                  type={apiData.type}
                  icon="robot"
                  planName={plan.name}
                  gb={plan.features[0]}
                  features={plan.features.slice(1)}
                  price={`$${plan.price}`}
                  stock={plan.stock === 0 ? false : true}
                />
              ))}
            {!apiData && loading && (
              <div>
                <br />
                <br />
                <br />
                <br />
                <div className="errorBox">
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>

                  <p className="error-text">Loading products...</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="product-box-features hidden-mobile hidden-tablet">
          <div className="product-box-features-container hidden-mobile hidden-tablet">
            <ProductDetail {...productData} featurecss="te" />
          </div>
        </div>
        <div className="hidden-large">
          <ProductDetail {...productData} featurecss="te" />
        </div>
        <div className="product-box-features">
          <div className="product-box-features-container">
            <h1 className="product-title">FAQ</h1>
            <FAQAccordion faqItems={faqItems} />
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default TerrariaHosting;
