import React, { useRef, useEffect } from "react";
import "./Modal.css";

const Modal = ({ isOpen, hasCloseBtn = true, onClose, title, children }) => {
  const modalRef = useRef(null);

  useEffect(() => {
    const modalElement = modalRef.current;

    const handleCloseModal = event => {
      if (event.key === "Escape" && isOpen) {
        onClose && onClose();
      }
    };

    if (modalElement) {
      if (isOpen) {
        modalElement.showModal();
        modalElement.addEventListener("keydown", handleCloseModal);
      } else {
        modalElement.close();
        modalElement.removeEventListener("keydown", handleCloseModal);
      }
    }

    return () => {
      modalElement.removeEventListener("keydown", handleCloseModal);
    };
  }, [isOpen, onClose]);

  return (
    <dialog ref={modalRef} className={`modal ${isOpen ? "open" : ""}`}>
      <div className="modal-header">
        {title && <h2 className="modal-title">{title}</h2>}
        {hasCloseBtn && (
          <button className="modal-close-btn hidden-mobile" onClick={onClose}>
            <span className="fa-stack fa-lg hidden-mobile">
              <i
                className="fas fa-square fa-stack-2x hidden-mobile"
                style={{ color: "red" }}
              ></i>
              <i className="fas fa-times fa-stack-1x fa-inverse hidden-mobile"></i>
            </span>
          </button>
        )}
      </div>
      <div className="modal-content">{children}</div>
    </dialog>
  );
};

export default Modal;
