import PanelImage1 from "../images/panel/image1.png";
import PanelImage2 from "../images/panel/image2.png";

const faqItems = [
  {
    question: "How reliable is BlueFoxHost's uptime for my services?",
    answer:
      "At BlueFoxHost, we take uptime seriously and work tirelessly to ensure that your services stay online. Our team actively monitors and manages the servers to minimize any downtime and provide you with a great uptime experience. Here at BlueFoxHost, we guarantee, 99.9% uptime for all BlueFoxHost services.",
  },
  {
    question: "What makes BlueFoxHost's panel different from others?",
    answer:
      "BlueFoxHost utilizes a customized version of the Pterodactyl Panel. Our team has invested significant effort in enhancing and adding numerous features to improve our clients' overall experience. You'll find a user-friendly and efficient panel designed to meet your hosting needs.",
  },
  {
    question: "How does BlueFoxHost ensure transparency with its customers?",
    answer:
      "Transparency is one of our core values at BlueFoxHost. To maintain openness with our customers, we publicly display server resource usage data. This way, you can have a clear understanding of the performance and resource allocation of your hosting services.",
  },
];

const productData = {
  title: "BlueFoxHost Panel",
  subtitle:
    "Our control panel streamlines server deployment, provides advanced server analytics, and integrates many additional features. We're dedicated to optimizing your hosting experience, so that you can do what you do best.",
  features: [
    { feature: "Easy to Use", icon: "fa fa-circle-check" },
    { feature: "Instant Deployment", icon: "fa fa-circle-check" },
    { feature: "Usage Statistics", icon: "fa fa-circle-check" },
    { feature: "Sub-User Support", icon: "fa fa-circle-check" },
    { feature: "Audit Logs", icon: "fa fa-circle-check" },
    { feature: "Simple API", icon: "fa fa-circle-check" },
    { feature: "2FA Support", icon: "fa fa-circle-check" },
    { feature: "Run Commands", icon: "fa fa-circle-check" },
  ],
  images: [PanelImage1, PanelImage2],
};

const featureData = [
  { icon: "fa fa-globe-europe", feature: "NA & EU Locations" },
  { icon: "fa fa-infinity", feature: "Unmetered Bandwidth" },
  { icon: "fa fa-folder", feature: "SFTP File Access" },
  { icon: "fa fa-network-wired", feature: "Additional Ports" },
  { icon: "fa fa-calendar-week", feature: "Scheduled Tasks" },
  { icon: "fa fa-chart-area", feature: "Resource Analytics" },
  { icon: "fa fa-database", feature: "phpMyAdmin Access" },
  { icon: "fa fa-users", feature: "Subuser Support" },
];

export { faqItems, featureData, productData };
