import React from "react";
import s from "../css/contact.css";
import Header from "../components/header";
import Footer from "../components/footer";

function ContactPage() {
  return (
    <div>
      <Header />

      <section className="contact">
        <div className="left-contact">
          <p className={s.contact_title}>Leave us a message</p>

          <form
            action="https://billing.bluefoxhost.com/submitticket.php"
            method="get"
          >
            <p className="input-label">Name</p>
            <input required min="1" className="input-contact" name="name" />

            <p className="input-label">Email Address</p>
            <input
              required
              type="email"
              className="input-contact"
              name="email"
            />

            <p className="input-label">Subject</p>
            <input required min="1" className="input-contact" name="subject" />

            <p className="input-label">Message</p>
            <textarea
              required
              className="textarea-contact"
              name="message"
            ></textarea>

            <input type="hidden" name="step" value="2"></input>
            <input type="hidden" name="deptid" value="1"></input>

            <button className="btn-send">Send</button>
          </form>
        </div>
        <div className="right-contact">
          <div>
            <p className="title-right">Leave us a message</p>
            <i className="envelope fa-regular fa-envelope"></i>
            <a href="mailto:hello@bluefoxhost.com" className="link-right">
              hello@bluefoxhost.com
            </a>
            <br />
            <i className="envelope fa-brands fa-discord"></i>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://discord.gg/jzCDhUU7uH"
              className="link-right"
            >
              Discord
            </a>
            <br />
            <i className="envelope fa-brands fa-x-twitter"></i>
            <a
              target="_blank"
              rel="noreferrer"
              href="https://twitter.com/BlueFoxHost"
              className="link-right"
            >
              Twitter
            </a>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
}

export default ContactPage;
