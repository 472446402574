import React, { useState } from "react";
import "../css/legal.css";
import Header from "../components/header";
import Footer from "../components/footer";
import LegalSection from "../components/LegalSection";
import TableOfContents from "../components/TableOfContents";
const PrivacyPolicy = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const sections = document.querySelectorAll(".title");
  const paragraphs = document.querySelectorAll("ul p");
  const highlightText = (text, searchTerm) => {
    const startIndex = text.toLowerCase().indexOf(searchTerm);
    if (startIndex === -1) return text;

    const endIndex = startIndex + searchTerm.length;
    const highlightedText =
      text.substring(0, startIndex) +
      '<span class="highlight">' +
      text.substring(startIndex, endIndex) +
      "</span>" +
      text.substring(endIndex);

    return highlightedText;
  };

  const highlightSearchTerm = searchTerm => {
    const searchWords = searchTerm.toLowerCase().split(/\s+/);

    sections.forEach(section => {
      section.innerHTML = section.textContent;

      const sectionTitle = section.textContent.toLowerCase();

      searchWords.forEach(word => {
        if (sectionTitle.includes(word)) {
          section.innerHTML = highlightText(section.textContent, word);
        }
      });

      paragraphs.forEach(paragraph => {
        const paragraphText = paragraph.textContent.toLowerCase();

        searchWords.forEach(word => {
          if (word.length >= 4 && paragraphText.includes(word)) {
            const regex = new RegExp(`\\b${word}\\b`, "gi");

            paragraph.innerHTML = paragraph.innerHTML.replace(
              regex,
              "<mark>$&</mark>"
            );
          }
        });
      });
    });
  };

  const clearAllHighlights = () => {
    paragraphs.forEach(paragraph => {
      paragraph.innerHTML = paragraph.textContent;
    });

    sections.forEach(section => {
      section.innerHTML = section.textContent;
    });
  };
  const handleSearchChange = event => {
    const searchTermText = event.target.value.toLowerCase();
    setSearchTerm(searchTermText);
    if (searchTermText.length < searchTerm.length) {
      clearAllHighlights();
      return;
    }
    highlightSearchTerm(searchTermText + " ");
  };

  const scrollToSelector = selector => {
    if (selector.includes("-")) {
      selector = selector.replace(/-+$/, "");
      const section = document.getElementById(selector);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      const sections = document.querySelectorAll(".title");
      sections.forEach(section => {
        if (
          section.textContent
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(selector.toLowerCase().replace(/\s+/g, ""))
        ) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  };

  const handleHashChange = () => {
    const hash = window.location.hash;
    if (hash) {
      scrollToSelector(hash.substring(1));
    }
  };

  window.addEventListener("hashchange", handleHashChange);
  window.addEventListener("load", handleHashChange);

  return (
    <div>
      <Header />
      <TableOfContents platform="hidden-mobile hidden-tablet" />
      <section className="legal-container">
        <div className="terms">
          <div className="head">
            <h1 className="title">Privacy Policy</h1>
            <h3 className="subtitle" ignore-toc="true">
              Last Updated: 9 March 2024
            </h3>
          </div>
          <TableOfContents platform="hidden-large" />
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search this document"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <LegalSection
            id="introduction"
            title="Introduction"
            listData={[
              {
                content:
                  "By utilizing any of BlueFoxHost’s services, including the website, Discord server, billing system, panel, etc., you (“client”, “you”, “your”) acknowledge and agree to the privacy policies outlined herein.",
                paragraph: true,
              },
              {
                content:
                  "We prioritize privacy and transparency at BlueFoxHost. This document aims to inform you about the data we collect, its purpose, importance, and your rights regarding its usage and removal from our databases. Personal data encompasses information such as Discord username & discriminator, email address, password, and IP Address.",
                paragraph: false,
              },
              {
                content:
                  "Please note that we may disclose your information if required by law.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="storing-of-information"
            title="Storing of Information"
            listData={[
              {
                content:
                  "All data collected is securely stored in our databases, ensuring the safety and confidentiality of your personal information.",
                paragraph: true,
              },
              {
                content:
                  "We employ stringent security measures such as secure password hashing and zero-trust policies to safeguard your data against unauthorized access or breaches.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="gdpr-regulations"
            title="GDPR Regulations"
            listData={[
              {
                content:
                  "At BlueFoxHost, we uphold your rights under the General Data Protection Regulation (GDPR), including the right to access, rectify, and erase your personal data.",
                paragraph: true,
              },
            ]}
          />

          <LegalSection
            id="coppa"
            title="COPPA"
            listData={[
              {
                content:
                  "BlueFoxHost does not knowingly collect data from individuals under the age of 13. However, data may be collected from users under 18 years of age. If you suspect that we have inadvertently collected data from a minor, please contact us immediately.",
                paragraph: true,
              },
            ]}
          />

          <LegalSection
            id="communication"
            title="Communication"
            listData={[
              {
                content:
                  "We respect your right to data removal and may communicate with you through various channels such as Discord, email, or phone calls regarding your purchased services.",
                paragraph: true,
              },
              {
                content:
                  "For any queries, requests for data removal, or other matters, please contact us at support@bluefoxhost.com with the relevant subject.",
                paragraph: false,
              },
            ]}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default PrivacyPolicy;
