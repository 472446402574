import React, { useState, useEffect } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/products.css";
import ProductBox from "../components/ProductBox";
import MinecraftBlockImage from "../images/products/minecraft-block.webp";
import MetaTags from "react-meta-tags";
/* ------- PANELS ------- */
import Features from "../components/Features";
import FAQAccordion from "../components/FAQAccordion";
import ProductDetail from "../components/ProductDetail";
import { faqItems, productData, featureData } from "../strings/productPanels";
import InfoBox from "../components/InfoBox";

const MinecraftPage = () => {
  const [apiData, setApiData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    fetch("https://api.bluefoxhost.com/products/1")
      .then(response => response.json())
      .then(data => {
        setApiData(data);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        console.error("Error fetching product data: ", error);
      });
  }, []);
  return (
    <div>
      <MetaTags>
        {/* Page title and description */}
        <title>Minecraft Hosting | BlueFoxHost</title>
        <meta
          name="description"
          content="Fast, reliable Minecraft Server Hosting. Powered by powerful enterprise-grade hardware and backed by our exceptional support."
        />

        {/* Site Info */}
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="BlueFoxHost" />
        <meta name="theme-color" content="#2cb1f8" />
        <meta property="og:image" content="/images/logo-full.png" />
        <meta property="og:locale" content="en_US" />
        <meta name="author" content="BlueFoxHost" />

        {/* SEO */}
        <meta
          property="og:title"
          content="Minecraft Server Hosting | BlueFoxHost"
        />
        <meta
          name="og:description"
          content="Fast, reliable Minecraft Server Hosting. Powered by powerful enterprise-grade hardware and backed by our exceptional support."
        />
        <meta
          name="description"
          content="Fast, reliable Minecraft Server Hosting. Powered by powerful enterprise-grade hardware and backed by our exceptional support."
        />
        <meta
          name="keywords"
          content="minecraft hosting, minecraft, host, budget, servers, cheap, support, budget, affordable, how to host, game hosting, source game, ark, rust, team fortress, discord bot, discord"
        />
      </MetaTags>
      <Header />
      <section className="products">
        <div className="product-box">
          <div className="product-side">
            <i className="controler fa-solid fa-gamepad"></i>
            <span className="product-name-game">Minecraft Server Hosting</span>
            <p className="product-title minecraft-text">
              Fast and reliable Minecraft hosting.
            </p>
            <p className="product-description">
              Building in creative mode, collective fighting in survival and
              hardcore mode, or just mini-games. Minecraft. Almost everyone
              knows it and millions of people play it almost every day. With
              BlueFox you have control over your server and get all the features
              you need and more!
            </p>
            {/* <a href="https://billing.bluefoxhost.com/index.php?rp=/store/minecraft-hosting">
                                <button className="btn-product-box minecraft">
                                    Order Now
                                </button>
                            </a> */}
          </div>
          <div className="product-side-right">
            <img
              className="product-image"
              src={MinecraftBlockImage}
              alt="Minecraft Block"
            />
          </div>
        </div>
        <div className="product-box-features">
          <h4
            className="section-heading"
            style={{ marginBottom: "0", textTransform: "uppercase" }}
          >
            Our basic features at no extra cost
          </h4>
          <p style={{ fontSize: "0.9rem" }} className="section-subheading">
            BlueFoxHost provides you with the ability to fully customize your
            hosting experience.
          </p>
          <Features features={featureData} itemcss="mc" />
        </div>
        <div className="products-boxes">
          <InfoBox
            message={[
              <p>
                Minecraft Hosting signups have been suspended. DO NOT REQUEST
                SERVERS. Learn more by clicking{" "}
                <a
                  style={{ textDecoration: "underline", color: "#2cb1f8" }}
                  target="_blank"
                  rel="noopener noreferrer"
                  href="https://bluefoxhost.com/company/announcements/1/Suspension-of-New-Signups-for-Free-Tier-for-Game-Hosting"
                >
                  here
                </a>
                .
              </p>,
            ]}
            color="error"
            dismissible={false}
            icon="fa-triangle-exclamation"
            inlinecss="info-box-title"
          />
        </div>
        {!apiData && !loading && (
          <div className="products-boxes">
            <InfoBox
              message={[
                <p>
                  An error occurred while loading products. Contact support{" "}
                  <a
                    style={{ textDecoration: "underline", color: "#2cb1f8" }}
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://billing.bluefoxhost.com/submitticket.php?step=2&deptid=1"
                  >
                    here
                  </a>
                  .
                </p>,
              ]}
              color="error"
              dismissible={false}
              icon="fa-triangle-exclamation"
              inlinecss="info-box-title"
            />
          </div>
        )}
        <div className="product-box-second">
          <div className="products-boxes">
            {apiData &&
              apiData.plans.map(plan => (
                <ProductBox
                  key={plan.id}
                  type={apiData.type}
                  icon="robot"
                  planName={plan.name}
                  gb={plan.features[0]}
                  features={plan.features.slice(1)}
                  price={`$${plan.price}`}
                  stock={plan.stock === 0 ? false : true}
                />
              ))}
            {!apiData && loading && (
              <div>
                <br />
                <br />
                <br />
                <br />
                <div className="errorBox">
                  <div class="lds-ring">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                  </div>

                  <p className="error-text">Loading products...</p>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className="product-box-features hidden-mobile hidden-tablet">
          <div className="product-box-features-container hidden-mobile hidden-tablet">
            <ProductDetail {...productData} featurecss="mc" />
          </div>
        </div>
        <div className="hidden-large">
          <ProductDetail {...productData} featurecss="mc" />
        </div>
        <div className="product-box-features">
          <div className="product-box-features-container">
            <h1 className="product-title">FAQ</h1>
            <FAQAccordion faqItems={faqItems} />
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MinecraftPage;
