import React, { useEffect, useState } from "react";
import tippy from "tippy.js";
import "tippy.js/dist/tippy.css";
import WorldImage from "../images/world.svg";
import axios from "axios";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/locations.css";
import CAImage from "../images/ca.png";
import USAImage from "../images/us.png";
import DEImage from "../images/de.png";
import FiImage from "../images/finland.png";
import NlImage from "../images/nl.png";
import serverIPs from "../strings/servers.js";

function WorldMap() {
  return (
    <div className="world-map">
      <div className="image-div">
        <img className="world-image" src={WorldImage} alt="World" />
        <div
          id="ashburn"
          style={{ left: "28.5%", top: "29%" }}
          className="pin tippy-trigger"
        ></div>
        <div
          id="montreal"
          style={{ left: "31%", top: "22.6%" }}
          className="pin tippy-trigger"
        ></div>
        <div
          id="nurnberg"
          style={{ left: "51.6%", top: "21%" }}
          className="pin tippy-trigger"
        ></div>
        <div
          id="newyork"
          style={{ left: "29.5%", top: "27.5%" }}
          className="pin tippy-trigger"
        ></div>
        <div
          id="helsinki"
          style={{ left: "54.6%", top: "13.6%" }}
          className="pin tippy-trigger"
        ></div>
        <div
          id="amsterdam"
          style={{ left: "50.4%", top: "18.9%" }}
          className="pin tippy-trigger"
        ></div>
      </div>
    </div>
  );
}

function Locations() {
  const [pingResults, setPingResults] = useState(false);
  useEffect(() => {
    const tooltips = {};

    const updatePingResults = async () => {
      const newPingResults = await Promise.all(
        serverIPs.map(async data => {
          const startTime = performance.now();
          const res = await axios.get(`https://${data.ip}`);
          const endTime = performance.now();
          const pingTime = endTime - startTime;
          if (res.status !== 200) return { ...data, ping: 0 };
          return { ...data, ping: pingTime };
        })
      );
      setPingResults(newPingResults);

      const pingContainers = document.querySelectorAll(".location-network");
      pingContainers.forEach((container, index) => {
        const locationId = newPingResults[index].locationId;
        const location = newPingResults[index].location;
        const ping = newPingResults[index].ping;
        const roundedPing = ping !== 0 ? Math.round(ping) : "Loading...";

        tooltips[locationId].setContent(`${location} (${roundedPing}ms)`);
      });
    };

    document.querySelectorAll(".tippy-trigger").forEach(trigger => {
      const locationId = trigger.id;
      tooltips[locationId] = tippy(`#${locationId}`)[0];
    });

    updatePingResults();

    const intervalId = setInterval(updatePingResults, 5000);
    return () => clearInterval(intervalId);
  }, []);

  return (
    <div>
      <Header />
      <section className="locations">
        <p className="title">Global Locations with a Vision to Expand</p>
        <p className="subtitle">
          With strategic locations around the world, BlueFoxHost is committed to
          our ongoing mission of expansion, ensuring we're always in the right
          place for your needs.
        </p>

        <WorldMap />

        <div className="locations-grid">
          {pingResults
            ? pingResults.map((result, index) => (
                <div className="location-div" key={index}>
                  <div className="flex-location">
                    <div className="side-flag">
                      <img
                        className="country-flag"
                        alt="Flag"
                        src={result.image}
                      />
                    </div>
                    <div className="side">
                      <div>
                        <p className="location-name">
                          {result.location} <br />
                          <span className="location-network">
                            ({Math.round(result.ping)}ms)
                          </span>
                        </p>
                        <p className="country-name">{result.countryName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : serverIPs.map((result, index) => (
                <div className="location-div" key={index}>
                  <div className="flex-location">
                    <div className="side-flag">
                      <img
                        className="country-flag"
                        alt="Flag"
                        src={result.image}
                      />
                    </div>
                    <div className="side">
                      <div>
                        <p className="location-name">
                          {result.location} <br />
                          <span className="location-network">(Loading...)</span>
                        </p>
                        <p className="country-name">{result.countryName}</p>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
        </div>
      </section>
      <Footer />
    </div>
  );
}

export default Locations;
