import React from "react";
import { useSpring, animated } from "@react-spring/web";

function Number({ num }) {
  const { number } = useSpring({
    from: { number: 0 },
    number: num,
    config: { mass: 1, tension: 20, friction: 10 },
    delay: 100,
  });

  return <animated.div>{number.to(n => `${n.toFixed(0)}+`)}</animated.div>;
}

export default Number;
