import React from "react";
import Features from "./Features";
import Carousel from "react-bootstrap/Carousel";
import "./ProductDetail.css";
import "../css/carousel.scss";

const ProductDetail = ({
  title,
  subtitle,
  features,
  images,
  featurecss = "",
}) => {
  return (
    <div>
      <div className="product-info">
        <div className="img-detail">
          <Carousel indicators={false} className="image-carousel-container">
            {images.map((image, index) => (
              <Carousel.Item key={index}>
                <img
                  className="img-detail"
                  src={image}
                  alt={`Image ${index}`}
                />
              </Carousel.Item>
            ))}
          </Carousel>
        </div>
        <div className="product-info product-detail">
          <div className="product-title-info">
            <h1>{title}</h1>
            <h2>{subtitle}</h2>
          </div>
          {features && (
            <Features
              features={features}
              itemcss={"feature-product-detail " + featurecss}
              //listcss="product-detail-featurelist"
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetail;
