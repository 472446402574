import React from "react";

const LegalSection = ({ id, title, listData }) => {
  const copyLinkToClipboard = sectionId => {
    const link = window.location.href.split("#")[0] + `#${sectionId}`;
    navigator.clipboard
      .writeText(link)
      .then(() => console.log("Link copied to clipboard"))
      .catch(error => console.error("Failed to copy link: ", error));
  };

  const renderListItems = () => {
    let isInParagraph = false;
    return listData.map((item, index) => {
      const { content, paragraph } = item;
      if (paragraph) {
        isInParagraph = true;
        return (
          <li key={index}>
            <p>{content}</p>
          </li>
        );
      } else {
        if (isInParagraph) {
          return (
            <li className="indented-bullet" key={index}>
              <p>{content}</p>
            </li>
          );
        } else {
          isInParagraph = false;
          return (
            <li key={index}>
              <p>{content}</p>
            </li>
          );
        }
      }
    });
  };

  return (
    <div id={id}>
      <h4 className="title">
        {title}{" "}
        <button
          className="permalink-button"
          onClick={() => copyLinkToClipboard(id)}
          title="Copy Link"
        >
          <i className="fa-solid fa-link link-button"></i>
        </button>
      </h4>
      <ul>{renderListItems()}</ul>
    </div>
  );
};

export default LegalSection;
