import React, { useEffect, useState } from "react";
import axios from "axios";
import "../css/company.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { useParams } from "react-router-dom";
import DOMPurify from "dompurify";
import { useNavigate } from "react-router-dom";
import InfoBox from "../components/InfoBox";
import ErrorImage from "../images/404.svg";

function AnnouncementsPage() {
  const { id } = useParams();
  const navigate = useNavigate();
  const [htmlData, setHtmlData] = useState(null);
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    const apiUrl = `https://cdn-news.talosbot.xyz/announcements-prod/bluefoxhost_${id}_announcement.html`;
    axios
      .get(apiUrl)
      .then(response => {
        const sanitizedHTML = DOMPurify.sanitize(response.data);
        setHtmlData(sanitizedHTML);
      })
      .catch(error => {
        console.error("Error fetching HTML data:", error);
        setLoading(false);
      });
  }, [id]);

  return (
    <div>
      <Header />

      <section className="company">
        {htmlData && <div dangerouslySetInnerHTML={{ __html: htmlData }}></div>}
        {!htmlData && !loading && (
          <div>
            <div className="errorBox">
              <img className="error-image" alt="Error" src={ErrorImage} />

              <p className="error-text">
                The requested announcement is unavailable or has been relocated
                to a different location.
              </p>
              <a href="/">
                <button className="error-button">Go to Homepage</button>
              </a>
            </div>
          </div>
        )}
        {!htmlData && loading && (
          <div>
            <br />
            <br />
            <br />
            <br />
            <div className="errorBox">
              <div class="lds-ring">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>

              <p className="error-text">
                The requested announcement is currently loading.
              </p>
              <a href="/">
                <button className="error-button">Go to Homepage</button>
              </a>
            </div>
          </div>
        )}
      </section>

      <Footer />
    </div>
  );
}

export default AnnouncementsPage;
