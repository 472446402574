import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/legal.css";
import LegalSection from "../components/LegalSection";
import TableOfContents from "../components/TableOfContents";
const ServiceLevelAgreement = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const sections = document.querySelectorAll(".title");
  const paragraphs = document.querySelectorAll("ul p");
  const highlightText = (text, searchTerm) => {
    const startIndex = text.toLowerCase().indexOf(searchTerm);
    if (startIndex === -1) return text;

    const endIndex = startIndex + searchTerm.length;
    const highlightedText =
      text.substring(0, startIndex) +
      '<span class="highlight">' +
      text.substring(startIndex, endIndex) +
      "</span>" +
      text.substring(endIndex);

    return highlightedText;
  };

  const highlightSearchTerm = searchTerm => {
    const searchWords = searchTerm.toLowerCase().split(/\s+/);

    sections.forEach(section => {
      section.innerHTML = section.textContent;

      const sectionTitle = section.textContent.toLowerCase();

      searchWords.forEach(word => {
        if (sectionTitle.includes(word)) {
          section.innerHTML = highlightText(section.textContent, word);
        }
      });

      paragraphs.forEach(paragraph => {
        const paragraphText = paragraph.textContent.toLowerCase();

        searchWords.forEach(word => {
          if (word.length >= 4 && paragraphText.includes(word)) {
            const regex = new RegExp(`\\b${word}\\b`, "gi");

            paragraph.innerHTML = paragraph.innerHTML.replace(
              regex,
              "<mark>$&</mark>"
            );
          }
        });
      });
    });
  };

  const clearAllHighlights = () => {
    paragraphs.forEach(paragraph => {
      paragraph.innerHTML = paragraph.textContent;
    });

    sections.forEach(section => {
      section.innerHTML = section.textContent;
    });
  };
  const handleSearchChange = event => {
    const searchTermText = event.target.value.toLowerCase();
    setSearchTerm(searchTermText);
    if (searchTermText.length < searchTerm.length) {
      clearAllHighlights();
      return;
    }
    highlightSearchTerm(searchTermText + " ");
  };

  const scrollToSelector = selector => {
    if (selector.includes("-")) {
      selector = selector.replace(/-+$/, "");
      const section = document.getElementById(selector);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      const sections = document.querySelectorAll(".title");
      sections.forEach(section => {
        if (
          section.textContent
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(selector.toLowerCase().replace(/\s+/g, ""))
        ) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  };

  const handleHashChange = () => {
    const hash = window.location.hash;
    if (hash) {
      scrollToSelector(hash.substring(1));
    }
  };

  window.addEventListener("hashchange", handleHashChange);
  window.addEventListener("load", handleHashChange);

  return (
    <div>
      <Header />
      <TableOfContents platform="hidden-mobile hidden-tablet" />
      <section className="legal-container">
        <div className="terms">
          <div className="head">
            <h1 className="title">Service Level Agreement</h1>
            <h3 className="subtitle" ignore-toc="true">
              Last Updated: 9 March 2024
            </h3>
          </div>
          <TableOfContents platform="hidden-large" />
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search this document"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <LegalSection
            id="introduction"
            title="Introduction"
            listData={[
              {
                content:
                  "Utilization of services provided by BlueFoxHost constitutes implicit acceptance of our Service Level Agreement (hereinafter referred to as the 'SLA'), encompassing all clauses contained therein.",
                paragraph: true,
              },
              {
                content:
                  "Should you dissent from any provision of the SLA, it is imperative to cease utilization of the service immediately.",
                paragraph: false,
              },
              {
                content:
                  "We retain the right to amend or modify any aspect of the SLA at our discretion and without prior notice.",
                paragraph: false,
              },
              {
                content:
                  "It is incumbent upon you to periodically review the SLA and acquaint yourself with any alterations or updates to its provisions.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="guarantee"
            title="Guarantee"
            listData={[
              {
                content:
                  "BlueFoxHost unequivocally assures a 99.9% uptime for all services rendered.",
                paragraph: false,
              },
              {
                content:
                  "It is imperative to note, however, that while utmost efforts are exerted to maintain service continuity, we cannot guarantee uninterrupted or error-free service.",
                paragraph: false,
              },
              {
                content:
                  "Should any of our physical machines fail to deliver requisite services for a consecutive duration of 15 minutes, the affected services shall be subject to coverage under our Service Level Agreement (SLA).",
                paragraph: false,
              },
              {
                content:
                  "BlueFoxHost provides certain free services; these services are not eligible for any service gurantees.",
                paragraph: true,
              },
              {
                content:
                  "These free services are provided as-is, without any warranties or guarantees, express or implied. BlueFoxHost does not warrant the accuracy, reliability, or availability of the free services, and disclaims all responsibility and liability for any harm or damage resulting from the use of the free services.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="claiming-compensation"
            title="Claiming Compensation"
            paragraph="To seek compensation for service outages, it is imperative that you adhere to the following protocols:"
            listData={[
              {
                content:
                  "Creation of a support ticket via our billing system, detailing the pertinent account affected by the outage.",
                paragraph: false,
              },
              {
                content:
                  "The aforementioned support ticket must be initiated within a stipulated timeframe of 3 days subsequent to the occurrence of the service outage.",
                paragraph: false,
              },
              {
                content:
                  "Failure to adhere to the prescribed timeline may result in forfeiture of entitlement to compensation.",
                paragraph: false,
              },
              {
                content:
                  "BlueFoxHost provides certain free services; these services are not eligible for compensation.",
                paragraph: true,
              },
            ]}
          />
          <LegalSection
            id="limitations"
            title="Limitations"
            paragraph="The Service Level Agreement (SLA) provided by BlueFoxHost shall govern the affected services under the subsequent conditions:"
            listData={[
              {
                content:
                  "During scheduled maintenance conducted by our technicians resulting in temporary service interruptions. Notification of all maintenance activities will be disseminated through our Discord channel, status page, and billing system.",
                paragraph: true,
              },
              {
                content:
                  "Situations arising from external factors beyond the purview of BlueFoxHost that cause service disruptions shall render our SLA inapplicable to the affected services.",
                paragraph: false,
              },
              {
                content:
                  "Installation of third-party software/scripts by the client leading to service downtime shall exempt the affected services from coverage under our SLA.",
                paragraph: false,
              },
              {
                content:
                  "Instances of service unavailability due to Denial of Service (DoS) or Distributed Denial of Service (DDoS) attacks shall not be covered by our SLA. It should be noted that our systems are engineered to withstand and mitigate substantial DDoS attacks.",
                paragraph: false,
              },
            ]}
          />
          <LegalSection
            id="free-services-disclaimer"
            title="Disclaimer for Free Services"
            listData={[
              {
                content:
                  "BlueFoxHost offers certain services free of charge. By using these free services, you acknowledge and agree that BlueFoxHost shall not be held responsible or liable for any damages, losses, or liabilities arising from the use or inability to use the free services provided.",
                paragraph: true,
              },
              {
                content:
                  "These free services are provided as-is, without any warranties or guarantees, express or implied. BlueFoxHost does not warrant the accuracy, reliability, or availability of the free services, and disclaims all responsibility and liability for any harm or damage resulting from the use of the free services.",
                paragraph: false,
              },
              {
                content:
                  "Furthermore, BlueFoxHost reserves the right to modify, suspend, or discontinue any free services at any time without prior notice. Users of the free services are solely responsible for their use of the services and agree to indemnify and hold harmless BlueFoxHost from any claims, damages, or losses arising from such use.",
                paragraph: false,
              },
            ]}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ServiceLevelAgreement;
