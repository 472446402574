import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import "../css/index.css";
import Header from "../components/header";
import Footer from "../components/footer";
import { MetaTags } from "react-meta-tags";
import FAQAccordion from "../components/FAQAccordion";
import GetStarted from "../components/GetStarted";

const faqItems = [
  {
    question: "How reliable is BlueFoxHost's uptime for my services?",
    answer:
      "At BlueFoxHost, we take uptime seriously and work tirelessly to ensure that your services stay online. Our team actively monitors and manages the servers to minimize any downtime and provide you with a great uptime experience. Here at BlueFoxHost, we guarantee, 99.9% uptime for all BlueFoxHost services.",
  },
  {
    question: "What makes BlueFoxHost's panel different from others?",
    answer:
      "BlueFoxHost utilizes a customized version of the Pterodactyl Panel. Our team has invested significant effort in enhancing and adding numerous features to improve our clients' overall experience. You'll find a user-friendly and efficient panel designed to meet your hosting needs.",
  },
  {
    question: "How does BlueFoxHost ensure transparency with its customers?",
    answer:
      "Transparency is one of our core values at BlueFoxHost. To maintain openness with our customers, we publicly display server resource usage data. This way, you can have a clear understanding of the performance and resource allocation of your hosting services.",
  },
];

function FirstSection() {
  return (
    <section data-aos="fade-up" className="first">
      <p className="title-big">Powerful & Secure Cloud Services</p>
      <p className="short-description">
        BlueFoxHost offers affordable pricing around the globe when it comes to
        all of our services. We provide premium support for each and every
        customer around the clock.
      </p>

      <GetStarted />

      <div className="center">
        <img className="laptops" src="/images/laptops.webp" alt="Laptops" />
      </div>
    </section>
  );
}
function SecondSection() {
  return (
    <section className="second">
      <div data-aos="fade-right" className="second-div">
        <div className="flex-side">
          <p className="title-second">The Most Advanced Hosting Provider</p>
          <p className="description-second">
            BlueFoxHost consistently expands its services to cater to your
            evolving needs, staying ahead with innovative solutions for
            businesses and individuals alike. Count on us for comprehensive
            support in today's dynamic digital landscape.
          </p>
        </div>
        <div className="flex-side">
          <div className="grid-layout">
            <div className="section-grid">
              <div className="number-section">
                <span className="fa-solid fa fa-server"></span>
              </div>
              <p className="title-section">99.9% Uptime</p>
              <p className="description-section">
                BlueFoxHost works to provide 99.9% uptime on all of our
                services. Day or night, we keep your services online.
              </p>
            </div>
            <div className="section-grid">
              <div className="number-section">
                <span className="fa-solid fa fa-chart-line"></span>
              </div>
              <p className="title-section">Highly Rated</p>
              <p className="description-section">
                BlueFoxHost is rated 4.7 out of 5 stars! Our customers love us
                for our reliable uptime, robust network, and friendly customer
                support.{" "}
              </p>
            </div>
            <div className="section-grid">
              <div className="number-section">
                <span className="fa-solid fa fa-magnifying-glass"></span>
              </div>
              <p className="title-section">Transparency</p>
              <p className="description-section">
                We try our absolute best to remain transparent with our
                customers which is why we display our server resource usage
                publicly.
              </p>
            </div>
            <div className="section-grid">
              <div className="number-section">
                <span className="fa-solid fa fa-dollar-sign"></span>
              </div>
              <p className="title-section">Affordable Pricing</p>
              <p className="description-section">
                Here at BlueFoxHost, we offer top notch support and powerful
                hardware at an affordable price.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
function ThirdSection() {
  return (
    <section data-aos="fade-down" className="third">
      <p className="small-title">Choose Your Game Service</p>
      <p className="small-description">
        Here at BlueFoxHost, you can deploy a server in a matter of minutes
        while getting top of the line support.
      </p>
      <div className="services-flex">
        <div className="section-service">
          <p className="section-title">Minecraft Hosting</p>
          <p className="section-price">
            $0.00<span className="section-monthly"></span>
          </p>
          <div className="features">
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">4GB Memory</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">2x vCPU Cores</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">40GB Disk Space</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">Unlimited Bandwidth</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">1 MySQL Database</span>
          </div>
          <a href="/products/minecraft">
            <button className="section-order">View Plans</button>
          </a>
        </div>
        <div className="section-service">
          <p className="section-title">Discord Bot Hosting</p>
          <p className="section-price">
            $0.00<span className="section-monthly"></span>
          </p>
          <div className="features">
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">2GB Memory</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">2x vCPU CPU</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">10GB Disk Space</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">Unlimited Bandwidth</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">1 MySQL Database</span>
          </div>
          <a href="/products/bots">
            <button className="section-order">View Plans</button>
          </a>
        </div>
        <div className="section-service">
          <p className="section-title">Terraria Hosting</p>
          <p className="section-price">
            $0.00<span className="section-monthly"></span>
          </p>
          <div className="features">
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">3GB Memory</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">1x vCPU CPU</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">30GB Disk Space</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">Unlimited Bandwidth</span>
            <br />
            <i className="checkmark fa-solid fa-check"></i>
            <span className="section-feature">1 MySQL Database</span>
          </div>
          <a href="/products/terraria">
            <button className="section-order">View Plans</button>
          </a>
        </div>
      </div>
      <div className="center top-20">
        <a href="/products/" className="section-other">
          see other products...
        </a>
      </div>
    </section>
  );
}
function FourthSection() {
  return (
    <section className="fourth">
      <div className="line-fade"></div>
      <div data-aos="fade-left" className="second-div">
        <div className="flex-side scroller">
          <p className="title-second">Top-rated.</p>
          <p className="description-second">
            Explore the reasons behind our glowing reviews on Trustpilot as our
            satisfied customers vouch for the top-notch service and exceptional
            quality that distinguishes us from the rest.
          </p>
          <i className="fa-solid fa-star star"></i>
          <i className="star fa-solid fa-star"></i>
          <i className="star fa-solid fa-star"></i>
          <i className="star fa-solid fa-star"></i>
          <i className="star fa-solid fa-star"></i>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://www.trustpilot.com/review/bluefoxhost.com"
            className="check-yourself"
          >
            Leave a review on TrustPilot
          </a>
        </div>
        <div className="flex-side zero">
          <div className="flex-section-mover">
            <div id="left" className="mover left">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trstp.lt/dSgtyd-91"
              >
                <div className="mover-section left-mover">
                  <img
                    className="mover-image"
                    alt="Mover Avatar"
                    src="https://user-images.trustpilot.com/64c2c935701fed001258269c/73x73.png"
                  />
                  <span className="mover-name">Matias Markelic</span>
                  <p className="mover-text">
                    This hosting service has been exceptional, consistently
                    delivering top-notch performance with no downtime or server
                    issues. The support staff has been consistently attentive
                    and responsive, promptly assisting me whenever needed. The
                    service maintains a robust 24/7 uptime and impressively fast
                    speeds. Furthermore, the community is excellent, fostering a
                    positive atmosphere with occasional giveaways to engage
                    users. Overall, I am thoroughly satisfied with this hosting
                    provider.
                  </p>
                </div>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trstp.lt/PK8-LwHXr"
              >
                <div className="mover-section left-mover">
                  <img
                    className="mover-image"
                    alt="Mover Avatar"
                    src="https://user-images.trustpilot.com/60245216b2252d001ad5b33f/73x73.png"
                  />
                  <span className="mover-name">Svero</span>
                  <p className="mover-text">
                    This hosting service is absolutely amazing! They're the best
                    out there. I couldn't find a better host even though I was
                    searching for months. This service is incredibly cheap, and
                    some plans are even for free! Another amazing thing about
                    this host is the uptime. They barely have any downtime, and
                    the ping is super good! The specifications they give are
                    amazing, even the free plan has amazing specs for the price.
                    They even host giveaways that give away paid plans! The
                    panel they use is very simple and easy to use. They provide
                    discord, game, vps, web hosting and more. As a side note,
                    they have an extensive API (e.g. Crypto, Game Stats) and
                    they have many other services such as a completely custom
                    panel that is going to release soon (controlpanel.gg) and
                    DiscordVote (discordvote.net). As a ending statement, I
                    would like to say that this is an absolutely amazing hosting
                    service with many opportunities to provide.
                  </p>
                </div>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trstp.lt/HSozWfVOj"
              >
                <div className="mover-section left-mover">
                  <img
                    className="mover-image"
                    alt="Mover Avatar"
                    src="https://user-images.trustpilot.com/650c9b1893829b0012976306/73x73.png"
                  />
                  <span className="mover-name">Lezetho</span>
                  <p className="mover-text">
                    BlueFoxHost has such amazing staff members and community!
                    They helped me with all of my problems with hosting and even
                    offered me a free subdomain because of the project I was
                    working on! It was even 24/7, which I did not expect because
                    most free hosting servers aren't that well done. Other than
                    that it's such a amazing hosting for everything you need!
                  </p>
                </div>
              </a>
            </div>
            <div id="right" className="mover right">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trstp.lt/tJwshy2pA"
              >
                <div className="mover-section">
                  <img
                    className="mover-image"
                    alt="Mover Avatar"
                    src="https://ui-avatars.com/api/background=2db0fe&color=fff?name=MA"
                  />
                  <span className="mover-name">Mostafa Askaf</span>
                  <p className="mover-text">
                    Amazing hosting with amazing owner! Everytime I have a
                    problem the owner fix it to me ;) This hosting is very good
                    and everyone should use it, And we never forget they closed
                    it just to make it better for us! Thanks for making this
                    free hosting.
                  </p>
                </div>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trstp.lt/V_7F2CXDb"
              >
                <div className="mover-section">
                  <img
                    className="mover-image"
                    alt="Mover Avatar"
                    src="https://user-images.trustpilot.com/60a7f95449f06e001accd1f3/73x73.png"
                  />
                  <span className="mover-name">Aanthro</span>
                  <p className="mover-text">
                    Getting a server is extremely easy, the staff are very
                    helpful, the community is amazing, whatever you host on a
                    server is extremely fast and easy to manage. The owner is a
                    very good and helpful person.
                  </p>
                </div>
              </a>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://trstp.lt/jPWaY13ky"
              >
                <div className="mover-section">
                  <img
                    className="mover-image"
                    alt="Mover Avatar"
                    src="https://user-images.trustpilot.com/602546af5e26c2001a95ff9d/73x73.png"
                  />
                  <span className="mover-name">Mr. C. Soldier</span>
                  <p className="mover-text">
                    The Hosting service is great - It provides services for
                    hosting games and gives an opportunity to use their VPS
                    (Virtual Private Service) where you can potentially make
                    your own game server and also be able to make your own game
                    on their Server! Their Server Supports Python and JavaScript
                    for programming applications/games/softwares as of February
                    11th 2021.
                  </p>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="line-fade"></div>
    </section>
  );
}

function FifthSection() {
  return (
    <section data-aos="fade-right" className="fifth">
      <p className="small-title">Frequently asked questions</p>
      <p className="small-description">Got a question? We have the answers.</p>
      <div className="second-div">
        <div className="flex-side center-img">
          <img
            src="/images/thinking.webp"
            alt="Man Thinking"
            className="man-thinking"
          />
        </div>
        <div className="flex-side large">
          <FAQAccordion faqItems={faqItems} />
        </div>
      </div>
    </section>
  );
}

function SixthSection() {
  return (
    <section data-aos="fade-up" className="sixth">
      <p className="ready-text">
        Ready? <span className="lets-host">Let's host!</span>
      </p>
      <p className="text-info">
        Here at BlueFoxHost, you can deploy a server in a matter of minutes
        while getting top of the line support.
      </p>
      <a href="/products">
        <button className="btn-start">Order Now</button>
      </a>
    </section>
  );
}

function HomePage() {
  useEffect(() => {
    AOS.init({
      once: true,
      disable: "mobile",
    });
  }, []);

  return (
    <div>
      <MetaTags>
        {/* Page title and description */}
        <title>Home | BlueFoxHost</title>
        <meta
          name="description"
          content="Fast, reliable Server Hosting. Powered by powerful enterprise-grade hardware and backed by our exceptional support."
        />
        {/* Preload */}
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href="/images/logo-text.webp"
          type="image/webp"
        ></link>
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href="/images/laptops.webp"
          type="image/webp"
        ></link>
        <link
          rel="preload"
          fetchpriority="high"
          as="image"
          href="/images/thinking.webp"
          type="image/webp"
        ></link>

        {/* Site Info */}
        <meta charSet="utf-8" />
        <meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=Edge" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="robots" content="index, follow" />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="BlueFoxHost" />
        <meta name="theme-color" content="#2cb1f8" />
        <meta property="og:image" content="/images/logo-full.png" />
        <meta property="og:locale" content="en_US" />
        <meta name="author" content="BlueFoxHost" />

        {/* SEO */}
        <meta property="og:title" content="Home | BlueFoxHost" />
        <meta
          name="og:description"
          content="Fast, reliable Server Hosting. Powered by powerful enterprise-grade hardware and backed by our exceptional support."
        />
        <meta
          name="description"
          content="Fast, reliable Server Hosting. Powered by powerful enterprise-grade hardware and backed by our exceptional support."
        />
        <meta
          name="keywords"
          content="minecraft hosting, minecraft, host, budget, servers, cheap, support, budget, affordable, how to host, game hosting, source game, ark, rust, team fortress, discord bot, discord"
        />
      </MetaTags>
      <Header />
      <FirstSection />
      <SecondSection />
      <ThirdSection />
      <FourthSection />
      <FifthSection />
      <SixthSection />
      <Footer />
    </div>
  );
}

export default HomePage;
