import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import "../css/legal.css";
import LegalSection from "../components/LegalSection";
import TableOfContents from "../components/TableOfContents";
const TermsAndConditions = () => {
  const [searchTerm, setSearchTerm] = useState("");

  const sections = document.querySelectorAll(".title");
  const paragraphs = document.querySelectorAll("ul p");
  const highlightText = (text, searchTerm) => {
    const startIndex = text.toLowerCase().indexOf(searchTerm);
    if (startIndex === -1) return text;

    const endIndex = startIndex + searchTerm.length;
    const highlightedText =
      text.substring(0, startIndex) +
      '<span class="highlight">' +
      text.substring(startIndex, endIndex) +
      "</span>" +
      text.substring(endIndex);

    return highlightedText;
  };

  const highlightSearchTerm = searchTerm => {
    const searchWords = searchTerm.toLowerCase().split(/\s+/);

    sections.forEach(section => {
      section.innerHTML = section.textContent;

      const sectionTitle = section.textContent.toLowerCase();

      searchWords.forEach(word => {
        if (sectionTitle.includes(word)) {
          section.innerHTML = highlightText(section.textContent, word);
        }
      });

      paragraphs.forEach(paragraph => {
        const paragraphText = paragraph.textContent.toLowerCase();

        searchWords.forEach(word => {
          if (word.length >= 4 && paragraphText.includes(word)) {
            const regex = new RegExp(`\\b${word}\\b`, "gi");

            paragraph.innerHTML = paragraph.innerHTML.replace(
              regex,
              "<mark>$&</mark>"
            );
          }
        });
      });
    });
  };

  const clearAllHighlights = () => {
    paragraphs.forEach(paragraph => {
      paragraph.innerHTML = paragraph.textContent;
    });

    sections.forEach(section => {
      section.innerHTML = section.textContent;
    });
  };
  const handleSearchChange = event => {
    const searchTermText = event.target.value.toLowerCase();
    setSearchTerm(searchTermText);
    if (searchTermText.length < searchTerm.length) {
      clearAllHighlights();
      return;
    }
    highlightSearchTerm(searchTermText + " ");
  };

  const scrollToSelector = selector => {
    if (selector.includes("-")) {
      selector = selector.replace(/-+$/, "");
      const section = document.getElementById(selector);
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    } else {
      const sections = document.querySelectorAll(".title");
      sections.forEach(section => {
        if (
          section.textContent
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(selector.toLowerCase().replace(/\s+/g, ""))
        ) {
          section.scrollIntoView({ behavior: "smooth", block: "start" });
        }
      });
    }
  };

  const handleHashChange = () => {
    const hash = window.location.hash;
    if (hash) {
      scrollToSelector(hash.substring(1));
    }
  };

  window.addEventListener("hashchange", handleHashChange);
  window.addEventListener("load", handleHashChange);

  return (
    <div>
      <Header />
      <TableOfContents platform="hidden-mobile hidden-tablet" />
      <section className="legal-container">
        <div className="terms">
          <div className="head">
            <h1 className="title">Terms &amp; Conditions</h1>
            <h3 className="subtitle" ignore-toc="true">
              Last Updated: 9 March 2024
            </h3>
          </div>
          <TableOfContents platform="hidden-large" />
          <div className="search-bar">
            <input
              type="text"
              placeholder="Search this document"
              value={searchTerm}
              onChange={handleSearchChange}
            />
          </div>
          <LegalSection
            id="introduction"
            title="Introduction"
            listData={[
              {
                content:
                  "By utilizing any of BlueFoxHost’s services, including but not limited to the website, Discord server, billing system, and panel, you, as the client, hereby acknowledge and agree to abide by the terms and conditions set forth herein.",
                paragraph: true,
              },
              {
                content:
                  "Your use of BlueFoxHost's services constitutes implicit acceptance of the terms outlined on this page. It is imperative that you review and understand these terms before continuing to use our services.",
                paragraph: true,
              },
              {
                content:
                  "Should you disagree with any of the terms or conditions specified herein, we advise you to refrain from using BlueFoxHost's services.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="cookies"
            title="Cookies"
            listData={[
              {
                content:
                  "We utilize cookies on BlueFoxHost's website. Your usage of our website implies your consent to the use of cookies in accordance with BlueFoxHost's privacy policy.",
                paragraph: true,
              },
              {
                content:
                  "Cookies are commonly employed by modern interactive websites to retrieve user details for each visit, facilitating functionality and user experience.",
                paragraph: false,
              },
              {
                content:
                  "Some areas of our site rely on cookies for optimal performance. Additionally, our affiliate and advertising partners may also utilize cookies.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="refunds"
            title="Refunds"
            listData={[
              {
                content:
                  "BlueFoxHost offers a refund policy that applies under certain circumstances.",
                paragraph: true,
              },
              {
                content:
                  "If a service experiences unexpected downtime within the first 24 hours of activation, the client is entitled to request a full refund.",
                paragraph: true,
              },
              {
                content:
                  "In cases where a service is found to be in violation of copyright or trademark laws, or any other American or International law, the service will be immediately suspended. If the infringement is not rectified, a forced cancellation will be enforced, with no refunds granted.",
                paragraph: false,
              },
              {
                content:
                  "However, in all other cases, BlueFoxHost may not offer refunds. This includes instances of early cancellations, credit added to your BlueFoxHost account, and downgrades made to existing services.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="48-hour-money-back-policy"
            title="48 Hour Money-Back Policy"
            listData={[
              {
                content:
                  "BlueFoxHost offers a 48-hour money-back policy for Game servers only.",
                paragraph: true,
              },
              {
                content:
                  "This policy is applicable only to new orders and is void if the client fails to initiate a cancellation within the specified time frame.",
                paragraph: true,
              },
              {
                content:
                  "To use this policy, you must open a support ticket detailing the reason for cancellation and refund request.",
                paragraph: true,
              },
              {
                content:
                  "It's important to note that VPS and Dedicated servers are not covered by our 48-hour money-back policy.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="chargebacks"
            title="Chargebacks"
            listData={[
              {
                content:
                  "Filing a chargeback against BlueFoxHost through any of our payment processors may result in severe consequences.",
                paragraph: true,
              },
              {
                content:
                  "These consequences include full account termination, removal of any services without prior notice, and potential imposition of fees on your account.",
                paragraph: true,
              },
              {
                content:
                  "In the event that we are unable to process the payment for the imposed fees from a payment method on file, your debt may be sold to a creditor who may proceed to collect on it.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="fair-use-policy"
            title="Fair Use Policy"
            listData={[
              {
                content:
                  "BlueFoxHost operates as a budget-friendly hosting provider, offering cost-effective solutions to our clients.",
                paragraph: true,
              },
              {
                content:
                  "However, it is essential to understand that our servers have finite resources and capacities.",
                paragraph: true,
              },
              {
                content:
                  "Excessive file uploads or resource usage beyond your allocated limits can negatively impact server performance for all users.",
                paragraph: true,
              },
              {
                content:
                  "Clients are expected to adhere to the allocated resource limits they have paid for, and any misuse or abuse may result in account termination.",
                paragraph: true,
              },
            ]}
          />

          <LegalSection
            id="user-conduct"
            title="User Conduct"
            listData={[
              {
                content:
                  "Users are prohibited from engaging in any illegal activities while using our services. This includes activities such as hacking, brute force attacks, mass emails, mass direct messages, raiding, IP spoofing, and similar actions.",
                paragraph: true,
              },
              {
                content:
                  "It is forbidden to upload, post, transmit, or use content that you do not own without proper authorization. This includes piracy, copyright infringement, identity theft, and related offenses.",
                paragraph: true,
              },
              {
                content:
                  "The use of DDoS attacks or botnet tools with the intent to harm others or their servers is strictly prohibited. Additionally, users must refrain from engaging in activities such as spamming, using keyloggers, proxy services, IRC/shell services, or any other objectionable behavior.",
                paragraph: true,
              },
              {
                content:
                  "Users must not interfere with or disrupt the service, servers, or networks connected to the service. Any violation of these guidelines may result in account termination.",
                paragraph: true,
              },
            ]}
          />

          <LegalSection
            id="nudity-and-sexually-explicit-material"
            title="Nudity and Sexually Explicit Material"
            listData={[
              {
                content:
                  "Users are strictly prohibited from uploading, sharing, or distributing any content that contains nudity or sexually explicit material while using our services.",
                paragraph: true,
              },
              {
                content:
                  "Violation of this policy may lead to the immediate termination of your account and access to our services. We take this matter seriously to maintain a safe and appropriate environment for all users.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="advertisements"
            title="Advertisements"
            listData={[
              {
                content:
                  "BlueFoxHost may occasionally send promotional material to your email address if you have opted in to receive marketing emails.",
                paragraph: true,
              },
              {
                content:
                  "You have the option to opt out of receiving such emails at any time by adjusting the settings in your customer account.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="accounts"
            title="Accounts"
            listData={[
              {
                content:
                  "To use BlueFoxHost's services, you must be at least 13 years of age. If you are under 13, you must obtain permission from your parent or guardian.",
                paragraph: true,
              },
              {
                content:
                  "BlueFoxHost retains the right to refuse service, terminate accounts, modify or remove content, or cancel orders at its sole discretion.",
                paragraph: true,
              },
            ]}
          />

          <LegalSection
            id="license"
            title="License"
            listData={[
              {
                content:
                  "Unless explicitly stated, BlueFoxHost and/or its licensors retain all intellectual property rights for the content on BlueFoxHost's website. All rights are reserved.",
                paragraph: true,
              },
              {
                content:
                  "You are permitted to view and/or print pages from https://bluefoxhost.com for personal use, subject to the restrictions outlined in these terms and conditions.",
                paragraph: false,
              },
              {
                content:
                  "You are prohibited from: Republishing material from https://bluefoxhost.com, selling, renting, or sublicensing material from https://bluefoxhost.com, reproducing, duplicating, or copying material from https://bluefoxhost.com, and redistributing content from BlueFoxHost unless specifically authorized.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="disclaimer"
            title="Disclaimer"
            listData={[
              {
                content:
                  "BlueFoxHost cannot be held liable for data loss, downtime, delays, or disconnection of services at any time.",
                paragraph: true,
              },
              {
                content:
                  "BlueFoxHost is not responsible for actions occurring within our services themselves.",
                paragraph: false,
              },
              {
                content:
                  "BlueFoxHost is not responsible for any damages, alterations, or actions taken by subusers on an account. Subusers are added at the discretion of the customer.",
                paragraph: false,
              },
              {
                content:
                  "BlueFoxHost is not liable for changes made outside of the initial server configuration. We reserve the right to refuse support for third-party software not included in the original setup.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="removal-of-links"
            title="Removal of links from our website"
            listData={[
              {
                content:
                  "If you find any link on our website or any linked website objectionable for any reason, you may contact us about this. We will consider requests to remove links but will have no obligation to do so or to respond directly to you.",
                paragraph: true,
              },
              {
                content:
                  "Whilst we work to ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we commit to ensuring that the website remains available or that the material on the website is kept up to date.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="content-liability"
            title="Content Liability"
            listData={[
              {
                content:
                  "We shall have no responsibility or liability for any content appearing on your website.",
                paragraph: true,
              },
              {
                content:
                  "You agree to indemnify and defend us against all claims arising out of or based upon your Website.",
                paragraph: false,
              },
              {
                content:
                  "No link(s) may appear on any page on your website or within any context containing content or materials that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="suspension-and-termination"
            title="Suspension and Termination"
            listData={[
              {
                content:
                  "We will suspend your service 3 days after the next due date of the invoice generated by the system if it is not paid.",
                paragraph: true,
              },
              {
                content:
                  "We will terminate your service 5 days after the next due date if the invoice is not paid.",
                paragraph: false,
              },
              {
                content:
                  "Additionally, we reserve the right to suspend or terminate your services upon any violation of these terms or in other cases deemed necessary for any reason whatsoever and at anytime without prior notice.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="support"
            title="Support"
            listData={[
              {
                content:
                  "We have a variety of ways that you can contact us this can be done by email, tickets and the live chat these are all displayed on our site.",
                paragraph: true,
              },
              {
                content:
                  "Any other support method is NOT considered official and you will not be guaranteed a response.",
                paragraph: false,
              },
              {
                content:
                  "Our support agents will do their utmost best to answer all requests in a reasonable time frame however this may vary depending on the complexity of the request and due to enquiry load.",
                paragraph: false,
              },
              {
                content:
                  "Using our support systems to transmit any material that harasses another person, profanity, and threats are strictly forbidden and may result in your account being limited.",
                paragraph: false,
              },
              {
                content:
                  "Our support agents can only provide support for BlueFoxHost services and only to the customer that purchased the service.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="right-to-modification"
            title="Right to Modification"
            listData={[
              {
                content:
                  "We reserve the right to modify any part of these terms at anytime with or without prior notification.",
                paragraph: true,
              },
              {
                content:
                  "It is your responsibility to check our website regularly for updates. The continued use of the service constitutes acceptance of these modifications.",
                paragraph: false,
              },
              {
                content:
                  "We reserve the right to modify service descriptions, quantity, pricing at anytime without prior notice.",
                paragraph: false,
              },
            ]}
          />

          <LegalSection
            id="promotions"
            title="Promotions"
            listData={[
              {
                content:
                  "BlueFoxHost cannot guarantee that promotions and promo codes remain operational at all times.",
                paragraph: true,
              },
            ]}
          />
          <LegalSection
            id="free-services-disclaimer"
            title="Disclaimer for Free Services"
            listData={[
              {
                content:
                  "BlueFoxHost offers certain services free of charge. By using these free services, you acknowledge and agree that BlueFoxHost shall not be held responsible or liable for any damages, losses, or liabilities arising from the use or inability to use the free services provided.",
                paragraph: true,
              },
              {
                content:
                  "These free services are provided as-is, without any warranties or guarantees, express or implied. BlueFoxHost does not warrant the accuracy, reliability, or availability of the free services, and disclaims all responsibility and liability for any harm or damage resulting from the use of the free services.",
                paragraph: false,
              },
              {
                content:
                  "Furthermore, BlueFoxHost reserves the right to modify, suspend, or discontinue any free services at any time without prior notice. Users of the free services are solely responsible for their use of the services and agree to indemnify and hold harmless BlueFoxHost from any claims, damages, or losses arising from such use.",
                paragraph: false,
              },
            ]}
          />
          <LegalSection
            id="business-hours"
            title="Business Hours"
            listData={[
              {
                content:
                  "Technical support is available 24/7/365 unless stated otherwise by our support team, however, tickets submitted on public holidays or during weekends may be delayed.",
                paragraph: true,
              },
              {
                content:
                  "All tickets average a response time of a few hours after submission, however, tickets can take up to 48 hours in rare cases.",
                paragraph: false,
              },
              {
                content:
                  "Other departments are available Monday through Friday (9 AM to 6 PM CDT).",
                paragraph: false,
              },
            ]}
          />
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
