import React from "react";
import "../css/footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div className="footer-flex">
        <div className="footer-side">
          <img
            className="logo-footer"
            src="/images/logo-text.webp"
            alt="Logo BlueFoxHosting"
          />
          <p className="company-shortdesc">
            A new take on reliable and affordable hosting. BlueFoxHost provides
            stable services while maintaining efficient customer service.
          </p>
        </div>
        <div className="footer-side">
          <p className="footer-title">Links</p>
          <a href="https://status.bluefoxhost.com/" className="footer-section">
            Service Status
          </a>
          <a href="https://billing.bluefoxhost.com/" className="footer-section">
            Billing Panel
          </a>
          <a href="https://panel.bluefoxhost.com/" className="footer-section">
            Hosting Panel
          </a>
        </div>
        <div className="footer-side">
          <p className="footer-title">Legal</p>
          <a href="/terms" className="footer-section">
            Terms of Service
          </a>
          <a href="/privacy" className="footer-section">
            Privacy Policy
          </a>
          <a href="/sla" className="footer-section">
            Service Level Agreement
          </a>
        </div>
        <div className="footer-side">
          <p className="footer-title">Company</p>
          <a href="/company/about" className="footer-section">
            About Us
          </a>
          <a href="/company/team" className="footer-section">
            Our Team
          </a>
          <a href="/locations" className="footer-section">
            Our Locations
          </a>
          <a href="/company/brand" className="footer-section">
            Brand Guidelines
          </a>
          <a href="/company/blocked_countries" className="footer-section">
            Restricted Countries
          </a>
        </div>
        <div className="footer-side">
          <p className="footer-title">Other</p>
          <a href="https://discord.gg/jzCDhUU7uH" className="footer-section">
            Discord Server
          </a>
          <a
            href="https://www.trustpilot.com/review/bluefoxhost.com"
            className="footer-section"
          >
            Trustpilot
          </a>
          <a href="https://twitter.com/BlueFoxHost" className="footer-section">
            Twitter
          </a>
        </div>
      </div>
      <div className="line-footer"></div>
      <p className="footer-cr">
        &copy; {new Date().getFullYear()} BlueFox Host - All rights reserved
      </p>
    </footer>
  );
}

export default Footer;
