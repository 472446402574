import React from "react";
import "../css/company.css";
import Header from "../components/header";
import Footer from "../components/footer";
import InfoBox from "../components/InfoBox";
import BluefoxLogoFull from "../images/logo-full.png";
function Careers() {
  return (
    <div>
      <Header />

      <section className="company">
        <div className="flex-about">
          <div className="side-about">
            <p className="title">Careers</p>
            <InfoBox
              message={[
                "No careers were found.",
                <br />,
                "Please check back later!",
              ]}
              color="warning"
              dismissible={false}
              icon="fa-triangle-exclamation"
            />
          </div>
          <div className="side-about center-about">
            <img alt="Logo" src={BluefoxLogoFull} className="logo-about" />
          </div>
        </div>
      </section>
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <br />
      <Footer />
    </div>
  );
}

export default Careers;
