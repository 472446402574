import React from "react";
import "../css/error.css";
import Header from "../components/header";
import Footer from "../components/footer";
import ErrorImage from "../images/404.svg";
function ErrorPage() {
  return (
    <div>
      <Header />

      <div className="errorBox">
        <img className="error-image" alt="Error" src={ErrorImage} />

        <p className="error-text">
          The requested resource is either unavailable or has been relocated to
          a different location.
        </p>
        <a href="/">
          <button className="error-button">Go to Homepage</button>
        </a>
      </div>

      <Footer />
    </div>
  );
}

export default ErrorPage;
